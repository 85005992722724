import React from 'react'
import DefaultRoleLayout from "./default-role-layout/DefaultRoleLayout";
// import CustomRoleLayout from './role-modal-layout/CustomRoleLayout';

const Components  = {
    default: DefaultRoleLayout,
    // "role-layout": CustomRoleLayout,
}

export default block => {
    // component does exist
    if (typeof Components[block.component] !== 'undefined') {
      return React.createElement(Components[block.component], {
        key: block.key,
        data: block.data,
        onSubmit: block.onSubmit,
        onCancel: block.onCancel,
      });
    }
    // component doesn't exist yet
    return React.createElement(Components['default'], {
        key: block.key,
        data: block.data,
        onSubmit: block.onSubmit,
        onCancel: block.onCancel,
    });
  }