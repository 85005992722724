import React, {useEffect, useState} from 'react';

const DTHDateRangeFilterLayout = props => {
  const [dateRange, setDateRange] = useState({fromDate: '', toDate: ''});
  const {fromDate, toDate} = dateRange;
  const {data: {namespace}, filterData, onFilterChange} = props;

  useEffect(() => {
    if (filterData && filterData.hasOwnProperty(namespace)) {
      const {fromDate = '', toDate = ''} = filterData[namespace];
      setDateRange({
        fromDate,
        toDate,
      });
    } else setDateRange({fromDate: '', toDate: ''});
  }, [filterData, namespace]);

  const handleDateChange = e => {
    const currFieldName = e.target.name;
    const currFieldDate = e.target.value;
    setDateRange(prevState => ({
      ...prevState,
      [currFieldName]: currFieldDate,
    }));
  };

  const setFilterData = () => {
    let tempFilterData = {...filterData};
    tempFilterData[namespace] = dateRange;
    onFilterChange(tempFilterData);
  };

  const resetFilter = () => {
    let tempFilterData = {...filterData};
    if (tempFilterData.hasOwnProperty(namespace)) {
      delete tempFilterData[namespace];
      onFilterChange(tempFilterData);
    }
  };

  return (
      <>
        <div className="dropdown-inner-section">
          <label htmlFor="start-date">From:</label>
          <input
              type="date"
              id="fromDate"
              name="fromDate"
              className="um-input"
              value={fromDate}
              max={toDate}
              onChange={handleDateChange}
          />
          <br/>
          <label htmlFor="end-date">To:</label>
          <input
              type="date"
              id="toDate"
              name="toDate"
              className="um-input"
              value={toDate}
              min={fromDate}
              onChange={handleDateChange}
          />
        </div>
        <div className="dropdown-inner-action-section">
          <button
              className="filter"
              type="button"
              onClick={setFilterData}
          >Filter
          </button>
          <button
              className="clear"
              type="button"
              onClick={resetFilter}
          >Clear
          </button>
        </div>
      </>
  );
};

export default DTHDateRangeFilterLayout;
