import React from 'react'
import DefaultModulesLayout from "../default_modules_layout/DefaultModulesLayout";

const Components  = {
    default: DefaultModulesLayout,
}

export default block => {
    // component does exist
    if (typeof Components[block.component] !== 'undefined') {
      return React.createElement(Components[block.component], {
        key: block.key,
        data: block.data,
        onSubmit: block.onSubmit,
        onCancel: block.onCancel,
        dropdownData:block.dropdownData
      });
    }
    // component doesn't exist yet
    return React.createElement(Components['default'], {
        key: block.key,
        data: block.data,
        onSubmit: block.onSubmit,
        onCancel: block.onCancel,
        dropdownData:block.dropdownData
    });
  }