import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSpinner,
	faEye,
	faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { addAlertToDOM, capitalizeFirstLetter } from '../../../../helpers/util';

const DefaultAddUserLayout = ({ onSubmit, onCancel }) => {
	const [submitLoading, setSubmitLoading] = useState(false);
	const [showPassword, setPasswordVisibility] = useState();
	const [showConfirmPassword, setConfirmPasswordVisibility] = useState();
	const { roles, groups, modalTimeout } = useSelector((state) => state.util);
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			email: '',
			status: true,
			firstName: '',
			lastName: '',
			role: '',
			group: '',
			password: '',
			confirmPassword: '',
		},
	});

	const passwordRef = useRef({});
	passwordRef.current = watch('password', '');

	const handlePasswordVisibilityChange = () =>
		setPasswordVisibility((prevState) => !prevState);
	const handleConfirmPasswordVisibilityChange = () =>
		setConfirmPasswordVisibility((prevState) => !prevState);

	const submitData = (data) => {
		const { email, firstName, lastName, role, group, password, status } = data;
		setSubmitLoading(true);
		const requestPayload = {
			login_id: email,
			email,
			first_name: firstName,
			last_name: lastName,
			roles: role === '' ? [] : [role],
			// groups: group === '' ? [] : [group],
			password,
			add_on_details:{
				groups: group === '' ? [] : [group],
				// status: status === true ? 'enabled' : 'disabled',
				status: status,
				full_name: `${firstName} ${lastName}`,
			}
		};

		axios
			.post('/api/v1/user/add', requestPayload)
			.then((response) => {
				if (response.status === 200) {
					addAlertToDOM(
						'.daul-alert-section',
						'success',
						'User added successfully',
						'mb-15'
					);
					onSubmit && setTimeout(() => onSubmit(), modalTimeout);
				} else {
					console.log(response);
					addAlertToDOM(
						'.daul-alert-section',
						'error',
						'Something went wrong. Try again.',
						'mb-15'
					);
				}
			})
			.catch((error) => {
				const errorResponse = error?.response?.data ?? {};
				if (errorResponse.hasOwnProperty('error')) {
					const errorData = errorResponse.error;
					console.log(errorData);
					if (errorData.hasOwnProperty('password_policy_errors')) {
						const passwordErrors = errorData.password_policy_errors;
						console.log(passwordErrors);
						const buildPasswordPolicyErrors = () => {
							let errorHtml = '';

							passwordErrors.forEach(({ error_msg }) => {
								errorHtml += `<li>${capitalizeFirstLetter(error_msg)}</li>`;
							});

							return errorHtml === '' ? '' : `<ul>${errorHtml}</ul>`;
						};
						addAlertToDOM(
							'.daul-alert-section',
							'error',
							`<p>Password policy not matched</p><ul>${buildPasswordPolicyErrors()}</ul>`,
							'mb-15',
							7000,
							true
						);
					} else if (errorData?.debug_msg === 'LoginID already exists') {
						addAlertToDOM(
							'.daul-alert-section',
							'error',
							'User already exist',
							'mb-15'
						);
					} else
						addAlertToDOM(
							'.daul-alert-section',
							'error',
							'Something went wrong. Try again.',
							'mb-15'
						);
				} else
					addAlertToDOM(
						'.daul-alert-section',
						'error',
						'Something went wrong. Try again.',
						'mb-15'
					);
			})
			.finally(() => setSubmitLoading(false));
	};

	const handleCancel = (e) => {
		onCancel && onCancel(e);
	};

	const buildRoleOptions = roles.map(({ _id, name }) => (
		<option value={_id} key={_id}>
			{name}
		</option>
	));
	const buildGroupOptions = groups.map(({ id, label }) => (
		<option value={id} key={id}>
			{label}
		</option>
	));

	return (
		<form
			onSubmit={submitLoading ? () => {} : handleSubmit(submitData)}
			noValidate
		>
			<div className='daul-alert-section' />
			<Row>
				<Col xs={6} className='mb-20'>
					<label for="email">Email :</label>
					<input
						name='email'
						id='email'
						type='email'
						className='um-input full-width'
						placeholder='Ex: john007@gmail.com'
						{...register('email', {
							required: true,
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: 'Invalid email address',
							},
						})}
					/>
					{errors?.email?.type === 'required' && (
						<p className='validation-error'>Email is required</p>
					)}
					{errors?.email?.type === 'pattern' && (
						<p className='validation-error'>Email is invalid</p>
					)}
				</Col>
				<Col xs={6} className='mb-20'>
					<label for="status">Status :</label>
					<div className='checkbox-wrapper'>
						<input
							id='status'
							name='status'
							type='checkbox'
							{...register('status')}
						/>
						<label className='cursor-pointer' htmlFor='status'>
							Enable
						</label>
					</div>
				</Col>
				<Col xs={6} className='mb-20'>
					<label for="firstName">First Name :</label>
					<input
						name='firstName'
						id='firstName'
						type='text'
						className='um-input full-width'
						placeholder='Ex: Jhon'
						{...register('firstName', {
							required: true,
						})}
					/>
					{errors?.firstName?.type === 'required' && (
						<p className='validation-error'>First name is required</p>
					)}
				</Col>
				<Col xs={6} className='mb-20'>
					<label for="lastName">Last Name :</label>
					<input
						name='lastName'
						id='lastName'
						type='text'
						className='um-input full-width'
						placeholder='Ex: John007'
						{...register('lastName')}
					/>
				</Col>
				<Col xs={6} className='mb-20'>
					<label for="role">Role :</label>
					<select
						name='role'
						id='role'
						className='um-select full-width'
						placeholder='Role'
						required
						{...register('role')}
					>
						<option value='' disabled defaultValue hidden>
							Select Role
						</option>
						{buildRoleOptions}
					</select>
				</Col>
				<Col xs={6} className='mb-20'>
					<label for="group">Group :</label>
					<select
						name='group'
						id='group'
						className='um-select full-width'
						placeholder='Role'
						required
						{...register('group')}
					>
						<option value='' disabled defaultValue hidden>
							Select Group
						</option>
						{buildGroupOptions}
					</select>
				</Col>
				<Col xs={6} className='mb-20'>
					<label for="password">Password :</label>
					<div className='inputPasswordWrapper'>
						<input
							type={showPassword ? 'text' : 'password'}
							name='password'
							id='password'
							className='um-input full-width'
							{...register('password', {
								required: true,
							})}
						/>
						<FontAwesomeIcon
							icon={showPassword ? faEyeSlash : faEye}
							className='password-visibility-icon'
							onClick={handlePasswordVisibilityChange}
						/>
					</div>
					{errors?.password?.type === 'required' && (
						<p className='validation-error'>Password is required</p>
					)}
					{errors?.password?.type === 'pattern' && (
						<p className='validation-error'>
							Password must contain minimum eight characters, at least one
							uppercase, one lowercase, one number and one special character
						</p>
					)}
				</Col>
				<Col xs={6} className='mb-20'>
					<label for="confirmPassword">Confim Password :</label>
					<div className='inputPasswordWrapper'>
						<input
							type={showConfirmPassword ? 'text' : 'password'}
							name='confirmPassword'
							className='um-input full-width'
							{...register('confirmPassword', {
								validate: (value) =>
									value === passwordRef.current || 'The passwords do not match',
							})}
						/>
						<FontAwesomeIcon
							icon={showConfirmPassword ? faEyeSlash : faEye}
							className='password-visibility-icon'
							onClick={handleConfirmPasswordVisibilityChange}
						/>
					</div>
					{errors?.confirmPassword?.type === 'validate' && (
						<p className='validation-error'>The passwords do not match</p>
					)}
				</Col>
				<Col xs={12} className='text-right'>
					<button
						type='submit'
						className='um-button um-button--primary mr-10'
						// onClick={submitData}
					>
						{submitLoading ? (
							<span>
								<FontAwesomeIcon
									className='loading-icon mr-5'
									icon={faSpinner}
									spin
								/>
								Submit
							</span>
						) : (
							'Submit'
						)}
					</button>
					<button type='button' className='um-button' onClick={handleCancel}>
						Cancel
					</button>
				</Col>
			</Row>
		</form>
	);
};

export default DefaultAddUserLayout;
