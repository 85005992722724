import React, {useEffect, useState} from 'react';

const DTHExtDateRangeFilterLayout = props => {
  const [dateRange, setDateRange] = useState({fromDate: '', toDate: ''});
  const {fromDate, toDate} = dateRange;
  const {
    data: {namespace},
    filterData,
    onFilterChange,
  } = props;

  useEffect(() => {
    if (filterData && filterData.hasOwnProperty(namespace)) {
      const {fromDate = '', toDate = ''} = filterData[namespace];
      setDateRange({
        fromDate,
        toDate,
      });
    } else setDateRange({fromDate: '', toDate: ''});
  }, [filterData, namespace]);

  const handleDateChange = e => {
    const currFieldName = e.target.name;
    const currFieldDate = e.target.value;
    setDateRange(prevState => ({
      ...prevState,
      [currFieldName]: currFieldDate,
    }));
  };

  const getDDMMYYYYDateString = date => {
    // let date = new Date();
    let yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // getMonth() is zero-based
    if (mm < 10) mm = '0' + mm;
    let dd = date.getDate();
    if (dd < 10) dd = '0' + dd;
    return `${yyyy}-${mm}-${dd}`;
  };

  const handlePrefixedDateRangeChange = e => {
    const selectedDays = e.target.value;
    const priorByDays = new Date(
        Date.now() - selectedDays * 24 * 60 * 60 * 1000);
    setDateRange({
      fromDate: getDDMMYYYYDateString(priorByDays),
      toDate: getDDMMYYYYDateString(new Date()),
    });
  };

  const sendFilterDataToParent = () => {
    let tempFilterData = {...filterData};
    tempFilterData[namespace] = dateRange;
    onFilterChange(tempFilterData);
  };

  const resetFilter = () => {
    let tempFilterData = {...filterData};
    if (tempFilterData.hasOwnProperty(namespace)) {
      delete tempFilterData[namespace];
      onFilterChange(tempFilterData);
    }
  };

  return (
      <>
        <div className="dropdown-inner-section">
          <select
              id="prefixed-date-rage-select"
              name="prefixed-date-rage-select"
              className="um-select"
              onChange={handlePrefixedDateRangeChange}
          >
            <option value={0}>Select prefixed ranges</option>
            <option value={7}>Past 7 days</option>
            <option value={30}>Past 30 days</option>
            <option value={90}>Past 3 months</option>
            <option value={180}>Past 6 months</option>
            <option value={365}>Past 1 year</option>
          </select>
          <h5 className="divider line razor">( or )</h5>
          <label htmlFor="fromDate">From:</label>
          <input
              type="date"
              id="fromDate"
              name="fromDate"
              className="um-input"
              value={fromDate}
              max={toDate}
              onChange={handleDateChange}
          />
          <label htmlFor="toDate">To:</label>
          <input
              type="date"
              id="toDate"
              name="toDate"
              className="um-input"
              value={toDate}
              min={fromDate}
              onChange={handleDateChange}
          />
        </div>
        <div className="dropdown-inner-action-section">
          <button
              className="filter"
              type="button"
              onClick={sendFilterDataToParent}
          >Filter
          </button>
          <button
              className="clear"
              type="button"
              onClick={resetFilter}
          >Clear
          </button>
        </div>
      </>
  );
};

export default DTHExtDateRangeFilterLayout;
