import React, { memo, useState } from 'react';
import Tippy from '@tippyjs/react';
import { calculateFixedWidthForColumn } from '../../../../helpers/util';
import DataTableHeaderAggregation from '../data_table_header_aggregation/DataTableHeaderAggregation';
import DataTableHeaderFilter from '../data_table_header_filter/DataTableHeaderFilter';

const DataTableHeader = memo((props) => {
	const {
		columnHeaders,
		enableColumnFilters,
		lastFixedColNamespace,
		data,
		index,
		filterData,
		onFilterChange,
	} = props;
	const { label, key, namespace, aggregation = false, filter, fixed } = data;

	const [activeFilter, setActiveFilter] = useState(false);

	const styles =
		fixed === true
			? {
					left:
						index === 0
							? 0
							: calculateFixedWidthForColumn(index, columnHeaders),
			  }
			: {};

	const handleActiveFilter = (x) => {
		setActiveFilter(x);
	};

	const buildHClassNames = `dt-th${fixed === true ? ' fixed' : ''}${
		activeFilter === true ? ' active-filter' : ''
	}`;

	return (
		<div
			className={buildHClassNames}
			style={styles}
			data-field-name={key}
			data-field-namespace={namespace}
			data-class={key}
			key={namespace}
		>
			<Tippy
				content={
					<div className='text-left'>
						<p className='m-0 p-0'>Name: {key}</p>
						<p className='m-0 p-0'>Alias: {label}</p>
					</div>
				}
			>
				<div className='dt-header-cell'>
					<div
						className='dt-header-cell-inner'
						data-tip
						data-for={'tooltip' + index}
					>
						<span>{label}</span>
					</div>
					{filter?.trueBackendType === 'number' &&
						filter?.type === 'number' &&
						aggregation && <DataTableHeaderAggregation data={data} />}
					{enableColumnFilters &&
						filter &&
						filter?.type !== 'none' &&
						namespace !== '' && (
							<DataTableHeaderFilter
								data={data}
								lastFixedColNamespace={lastFixedColNamespace}
								filterData={filterData}
								onFilterChange={onFilterChange}
								onActiveFilter={handleActiveFilter}
							/>
						)}
				</div>
			</Tippy>
		</div>
	);
});

function areEqual(prevProps, nextProps) {
	return prevProps === nextProps;

	/*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}

export default DataTableHeader;
