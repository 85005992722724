import React, { useEffect, useRef } from 'react';
import editUserLayout from './inner_components/edit_user_layout/index';

const EditUserModal = (props) => {
	const modalContentRef = useRef(null);
	const {
		title = '',
		visible = false,
		data,
		width = 650,
		onSubmit,
		onCancel,
	} = props;

	useEffect(() => {
		if (!visible) {
			document.body.style.overflow = 'unset';
			return;
		}

		document.body.style.overflow = 'hidden';

		document.addEventListener('click', hideDropdownMenu);
		return () => {
			document.body.style.overflow = 'unset';
			document.removeEventListener('click', hideDropdownMenu);
		};
	}, [visible]);

	const hideDropdownMenu = (event) => {
		if (modalContentRef?.current?.contains(event.target) === false) {
			// onCancel && onCancel();
		}
	};

	const closeModal = (e) => {
		e && e.stopPropagation();
		onCancel && onCancel();
	};

	const modalContentStyles = { maxWidth: width };

	const getEditUserLayout = () => {
		return editUserLayout({
			key: `edit-user`,
			component: `edit-user`,
			data,
			onSubmit,
			onCancel: closeModal,
		});
	};

	useEffect(()=>{
		if (window?.umApp?.DoOverrideEditUserModalComponent) {
				window?.umApp?.DoOverrideEditUserModalComponent();
			}
	  },[])

	return (
		<div className={visible ? 'um-modal show' : 'um-modal hide'}>
			<div
				className='um-modal-content'
				style={modalContentStyles}
				ref={modalContentRef}
			>
				<div className='um-modal-header'>
					<p className='um-modal-header-text'>
						{title}{' '}
						<span className='close' onClick={closeModal}>
							&times;
						</span>
					</p>
				</div>
				<div className='um-modal-body'>{getEditUserLayout()}</div>
			</div>
		</div>
	);
};

export default EditUserModal;
