import React, {useEffect, useRef} from 'react';
import roleLayout from './inner_components/RoleLayout'
import './RoleModal.scss';

const RoleModal = props => {
  const modalContentRef = useRef(null);
  const {title = '', visible = false, data, width = 650, onSubmit, onCancel} = props;

  useEffect(() => {
    if (!visible) {
      document.body.style.overflow = 'unset';
      return;
    }

    document.body.style.overflow = 'hidden';

    document.addEventListener('click', hideDropdownMenu);
    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('click', hideDropdownMenu)
    };
  }, [visible]);

  const hideDropdownMenu = event => {
    if (modalContentRef?.current?.contains(event.target) === false) {
      onCancel && onCancel();
    }
  };

  const closeModal = e => {
    e && e.stopPropagation();
    onCancel && onCancel();
  };

  const modalContentStyles = {maxWidth: width};

  const getRoleLayout = () => {
    return roleLayout({
      key: title.toLowerCase().replace(" ","-"),
      component: `role-layout`,
      data,
      onSubmit,
      onCancel: closeModal,
    });
  };

  useEffect(()=>{
    if (window?.umApp?.DoOverrideRoleModalComponent) {
			window?.umApp?.DoOverrideRoleModalComponent();
		}
  },[])

  return (
      <div className={visible ? 'um-modal show' : 'um-modal hide'}>
        <div
            className="um-modal-content"
            style={modalContentStyles}
            ref={modalContentRef}
        >
          <div className="um-modal-header">
            <p className="um-modal-header-text">{title} <span className="close" onClick={closeModal}>&times;</span></p>
          </div>
          <div className="um-modal-body">
            {getRoleLayout()}
          </div>
        </div>
      </div>
  );
};

export default RoleModal;
