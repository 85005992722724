import React, { useEffect, useReducer } from 'react';
import axios from 'axios';
import UsersFilter from '../../components/users_filter/UsersFilter';
import DataTable from '../../components/data_table/DataTable';
import AddUserModal from '../../components/add-user-modal/AddUserModal';
import EditUserModal from '../../components/edit_user_modal/EditUserModal';
import ChangeUserPasswordModal from '../../components/change_user_password_modal/ChangeUserPasswordModal';
import UsersExport from '../../components/users_export/UsersExport';
import { isEmptyObject } from '../../helpers/util';
import './Users.scss';
import DeleteUserModal from '../../components/delete_user_modal/DeleteUserModal';
import { useSelector } from 'react-redux';

const initialState = {
	filterData: {
		role: '',
		group: '',
		filterName: '',
	},
	sortData: {},
	usersData: {
		status: 'loading',
		list: [],
		pagination: {
			pageNumber: 0,
			count: 10,
			total: 0,
		},
	},
	showAddUserModal: false,
	showEditUserModal: false,
	editUserData: null,
	showChangeUserPasswordModal: false,
	showDeleteUserModal: false,
};

const reducer = (state = initialState, action) => {
	let tempState = { ...state };
	switch (action.type) {
		case 'UPDATE_USERS_DATA':
			tempState.usersData.status = 'success';
			tempState.usersData.list = action.payload;
			break;
		case 'UPDATE_USERS_DATA_TOTAL_COUNT':
			tempState.usersData.status = 'success';
			tempState.usersData.list = action.payload;
			tempState.usersData.pagination.total = 0;
			break;
		case 'UPDATE_USER_DATA_TOTAL_COUNT':
			tempState.usersData.pagination.total = action.payload;
			break;
		case 'UPDATE_USERS_DATA_STATUS':
			tempState.usersData.status = action.payload;
			break;
		case 'UPDATE_SORT_DATA':
			tempState.sortData = action.payload;
			break;
		case 'UPDATE_USERS_PAGINATION_DATA':
			tempState.usersData.pagination = action.payload;
			break;
		case 'UPDATE_FILTER_DATA':
			tempState.filterData = action.payload;
			break;
		case 'UPDATE_ADD_USER_MODAL_VISIBILITY':
			tempState.showAddUserModal = action.payload;
			break;
		case 'UPDATE_EDIT_USER_MODAL_VISIBILITY':
			tempState.showEditUserModal = action.payload;
			break;
		case 'UPDATE_EDIT_USER_DATA_AND_MODAL':
			tempState.showEditUserModal = action.payload.showEditUserModal;
			tempState.editUserData = action.payload.editUserData;
			break;
		case 'UPDATE_EDIT_USER_DATA_AND_CHANGE_USER_PASSWORD_MODAL_VISIBILITY':
			tempState.editUserData = action.payload.editUserData;
			tempState.showChangeUserPasswordModal =
				action.payload.showChangeUserPasswordModal;
			break;
		case 'UPDATE_CHANGE_USER_PASSWORD_MODAL':
			tempState.showChangeUserPasswordModal = action.payload;
			break;
		case 'UPDATE_EDIT_USER_DATA_AND_DELETE_USER_MODAL_VISIBILITY':
			tempState.editUserData = action.payload.editUserData;
			tempState.showDeleteUserModal = action.payload.showDeleteUserModal;
			break;
		case 'UPDATE_DELETE_USER_MODAL_VISIBILITY':
			tempState.showDeleteUserModal = action.payload;
			break;
		case 'RESET_FILTER_SORT_PAGINATION':
			const tmp = {
				...tempState,
				filterData: {
					role: '',
					group: '',
					filterName: '',
				},
				sortData: {},
				usersData: {
					list: [],
					status: 'loading',
					pagination: {
						pageNumber: 0,
						count: 10,
						total: 0,
					},
				}
			}
			tempState = tmp
			break;
		default:
			break;
	}
	return tempState;
};

const Users = () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { urlHistory} = useSelector((state) => state.util);
	const {
		sortData,
		filterData,
		usersData: { status, list, pagination },
		showAddUserModal,
		showEditUserModal,
		showChangeUserPasswordModal,
		editUserData,
		showDeleteUserModal
	} = state;
	const { role, group, filterName } = filterData;
	const { pageNumber, count } = pagination;
	const { userTableHeaders = [] } = window['runConfig'];

	useEffect(() => {
		getUsersList();
	}, [pageNumber, filterData, sortData]);

	useEffect(()=>{
		const {prev,cur} = urlHistory
		if (prev !== cur) {
			if (pageNumber != 0 || filterData.filterName != "" || filterData.group != "" || filterData.role != "" || Object.keys(sortData).length > 0   ) {
				dispatch({
					type: 'RESET_FILTER_SORT_PAGINATION',
				});
			}
			
		}
	},[urlHistory])

	const getUsersList = () => {
		dispatch({
			type: 'UPDATE_USERS_DATA_STATUS',
			payload: 'loading',
		});

		const requestPayload = {
			role,
			group,
			filterName,
			pageNumber,
			count,
		};

		if (!isEmptyObject(sortData)) {
			let tempSortData = { ...sortData };
			if (tempSortData.hasOwnProperty('first_name')) {
				tempSortData.name = tempSortData.first_name;
				delete tempSortData.first_name;
			}

			requestPayload.query = {
				$sort: tempSortData,
			};
		}

		axios
			.post('/api/v1/user/getall', requestPayload)
			.then((response) => {
				if (response.status === 200) {
					dispatch({
						type: 'UPDATE_USERS_DATA',
						payload: response?.data ?? [],
					});
					getTotalRecordsCount(requestPayload);
				} else
					dispatch({
						type: 'UPDATE_USERS_DATA_TOTAL_COUNT',
						payload: [],
					});
			})
			.catch((error) => {
				dispatch({
					type: 'UPDATE_USERS_DATA_TOTAL_COUNT',
					payload: [],
				});
			});
	};

	const getTotalRecordsCount = (requestPayload) => {
		axios
			.post('/api/v1/user/get/count', requestPayload)
			.then((response) => {
				if (response.status === 200) {
					dispatch({
						type: 'UPDATE_USER_DATA_TOTAL_COUNT',
						payload: response?.data?.data ?? 0,
					});
				} else
					dispatch({
						type: 'UPDATE_USER_DATA_TOTAL_COUNT',
						payload: 0,
					});
			})
			.catch((error) => {
				console.log(error.response);
				dispatch({
					type: 'UPDATE_USER_DATA_TOTAL_COUNT',
					payload: 0,
				});
			});
	};

	const handleSortChange = (data) => {
		let tempSortData = { ...data };
		const currColumnKey = Object.keys(data)[0];

		if (tempSortData.hasOwnProperty(currColumnKey)) {
			if (data[currColumnKey] === 0) {
				delete tempSortData[currColumnKey];
			} else {
				tempSortData[currColumnKey] = data[currColumnKey];
			}
		} else tempSortData[currColumnKey] = data[currColumnKey];

		dispatch({
			type: 'UPDATE_SORT_DATA',
			payload: tempSortData,
		});
	};

	const handlePaginationChange = (data) => {
		dispatch({
			type: 'UPDATE_USERS_PAGINATION_DATA',
			payload: data,
		});
	};

	const handleAction = (type, index) => {
		if (type === 'edit') {
			dispatch({
				type: 'UPDATE_EDIT_USER_DATA_AND_MODAL',
				payload: {
					editUserData: list[index],
					showEditUserModal: true,
				},
			});
		} else if (type === 'change-password') {
			dispatch({
				type: 'UPDATE_EDIT_USER_DATA_AND_CHANGE_USER_PASSWORD_MODAL_VISIBILITY',
				payload: {
					editUserData: list[index],
					showChangeUserPasswordModal: true,
				},
			});
		}else if (type === 'delete') {
			dispatch({
				type: 'UPDATE_EDIT_USER_DATA_AND_DELETE_USER_MODAL_VISIBILITY',
				payload: {
					editUserData: list[index],
					showDeleteUserModal: true,
				},
			});
		}
		console.log(type, index);
	};

	const handleFilterDataChange = (data) => {
		dispatch({
			type: 'UPDATE_FILTER_DATA',
			payload: data,
		});
	};

	const openAddUserModal = () => {
		dispatch({
			type: 'UPDATE_ADD_USER_MODAL_VISIBILITY',
			payload: true,
		});
	};

	const handleAddUserSubmit = () => {
		dispatch({
			type: 'UPDATE_ADD_USER_MODAL_VISIBILITY',
			payload: false,
		});
		getUsersList();
	};

	const closeAddUserModal = () => {
		dispatch({
			type: 'UPDATE_ADD_USER_MODAL_VISIBILITY',
			payload: false,
		});
	};

	const handleEditUserSubmit = () => {
		dispatch({
			type: 'UPDATE_EDIT_USER_DATA_AND_MODAL',
			payload: {
				editUserData: null,
				showEditUserModal: false,
			},
		});
		getUsersList();
	};

	const closeEditUserModal = () => {
		dispatch({
			type: 'UPDATE_EDIT_USER_DATA_AND_MODAL',
			payload: {
				editUserData: null,
				showEditUserModal: false,
			},
		});
	};

	const handleDeleteUserSubmit = () => {
		dispatch({
			type: 'UPDATE_EDIT_USER_DATA_AND_DELETE_USER_MODAL_VISIBILITY',
			payload: {
				editUserData: null,
				showDeleteUserModal: false,
			},
		});
		getUsersList();
	};

	const closeDeleteUserModal = () => {
		dispatch({
			type: 'UPDATE_EDIT_USER_DATA_AND_DELETE_USER_MODAL_VISIBILITY',
			payload: {
				editUserData: null,
				showDeleteUserModal: false,
			},
		});
	};

	const closeChangeUserPasswordModal = () => {
		dispatch({
			type: 'UPDATE_EDIT_USER_DATA_AND_CHANGE_USER_PASSWORD_MODAL_VISIBILITY',
			payload: {
				editUserData: null,
				showChangeUserPasswordModal: false,
			},
		});
	};

	useEffect(()=>{
		if (window?.umApp?.DoOverrideUsersPage) {
			window?.umApp?.DoOverrideUsersPage();
		}
	},[])


	return (
		<div className='users-section'>
			<div className='users-filter-section'>
				<div className='users-filter-left-section'>
					<UsersFilter onFilter={handleFilterDataChange} />
				</div>
				<div className='users-filter-right-section'>
					<UsersExport filterData={filterData} />
					<button
						className='um-button um-button--enter'
						onClick={openAddUserModal}
					>
						Add User
					</button>
				</div>
			</div>
			<DataTable
				loading={status === 'loading'}
				columnHeaders={userTableHeaders}
				data={list}
				sortData={sortData}
				onSortChange={handleSortChange}
				filterData={{}}
				onFilterChange={(data) => console.log(data)}
				pagination={pagination}
				onPaginationChange={handlePaginationChange}
				onAction={handleAction}
			/>
			{showAddUserModal && (
				<AddUserModal
					title='Add User'
					visible={showAddUserModal}
					onSubmit={handleAddUserSubmit}
					onCancel={closeAddUserModal}
				/>
			)}
			{showEditUserModal && (
				<EditUserModal
					title='Edit User'
					visible={showEditUserModal}
					data={editUserData}
					onSubmit={handleEditUserSubmit}
					onCancel={closeEditUserModal}
				/>
			)}
			{showChangeUserPasswordModal && (
				<ChangeUserPasswordModal
					title='Change Password'
					visible={showChangeUserPasswordModal}
					data={editUserData}
					onSubmit={closeChangeUserPasswordModal}
					onCancel={closeChangeUserPasswordModal}
				/>
			)}
			{showDeleteUserModal && (
				<DeleteUserModal
					title='Delete User'
					visible={showDeleteUserModal}
					data={editUserData}
					onSubmit={handleDeleteUserSubmit}
					onCancel={closeDeleteUserModal}
				/>
			)}
		</div>
	);
};

export default Users;
