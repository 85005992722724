// import './helpers/wdyr'; // Why did you render component import
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import './index.scss';

const styleCache = createCache({
	key: 'sdq',
	nonce: 'w51UFM0uev/LhCA+J2h7Bw==',
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<CacheProvider value={styleCache}>
				<Router>
					<App />
				</Router>
			</CacheProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
