import { Redirect, Route, Switch } from 'react-router-dom';
import { config } from '@fortawesome/fontawesome-svg-core';
import { Home } from './pages';
import BaseLayoutWrapper from './components/base_layout_wrapper/BaseLayoutWrapper';
import ScrollToTop from './components/scroll_to_top/ScrollToTop';
import { createAxiosInterceptor } from './axios-interceptor';
import './App.scss';

config.autoAddCss = false;

createAxiosInterceptor();

function App() {
	return (
		<ScrollToTop>
			<Switch>
				<Route exact path='/'>
					<Redirect to='/app/' />
				</Route>
				<Route path='/home/'>
					<Home />
				</Route>
				<Route path='/app'>
					<BaseLayoutWrapper />
				</Route>
			</Switch>
		</ScrollToTop>
	);
}

export default App;
