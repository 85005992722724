import React, { useState } from 'react';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import axios from 'axios';
import downloadExcelIcon from '../../assets/images/download_excel.png';
import { downloadFileFromUrl } from '../../helpers/util';
import './UsersExport.scss';

const { userTableHeaders = [] } = window['runConfig'];

const UsersExport = ({ filterData }) => {
	const [excelButtonData, setExcelButtonData] = useState({
		dropdownVisibility: false,
		status: '',
		fileData: {
			name: '',
			url: '',
		},
	});

	const {
		dropdownVisibility: excelDropdownVisibility,
		status: excelStatus,
		fileData: { name: excelFileName, url: excelFileUrl },
	} = excelButtonData;

	const openExcelDropdown = () => {
		setExcelButtonData({
			dropdownVisibility: true,
			status: 'loading',
			fileData: {
				name: '',
				url: '',
			},
		});
		// exportToExcel();
	};

	const closeExcelDropdown = () => {
		setExcelButtonData({
			dropdownVisibility: false,
			status: '',
			fileData: {
				name: '',
				url: '',
			},
		});
	};
	const generateHeader = () => {
		let res = {};
		userTableHeaders.forEach((dt) => {
			if (dt.export === true) {
				res[dt.key] = dt.label;
			}
		});
		return res;
	};
	const getExcelFile = () => {
		// setExcelButtonData({
		//     dropdownVisibility: true,
		//     status: 'loading',
		//     fileData: {
		//     name: '',
		//     url: '',
		//     },
		// })
		const payload = {
			...filterData,
			export_fields: generateHeader(),
		};
		axios
			.post('/api/v1/user/getall?export=true', payload, {
				headers: {
					'Content-Disposition': `attachment; filename=Usermanagement-User-${new Date().toISOString()}.xlsx`,
					'Content-Type':
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				},
				responseType: 'arraybuffer',
			})
			.then((response) => {
				if (response.status === 200) {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute(
						'download',
						`Usermanagement-User-${new Date().toISOString()}.xlsx`
					);
					document.body.appendChild(link);
					link.click();
				}
			})
			.catch((error) => {
				console.log(error.response);
				setExcelButtonData({
					dropdownVisibility: true,
					status: 'fail',
					fileData: {
						name: '',
						url: '',
					},
				});
			});
	};

	const downloadExcelFile = () => {
		downloadFileFromUrl(excelFileName, excelFileUrl);
	};

	return (
		<div style={{ position: 'relative' }}>
			<Tippy content='Download to Excel'>
				<img
					data-tip
					data-for='download-excel'
					className='cursor-pointer mr-10'
					src={downloadExcelIcon}
					width={30}
					alt='Download excel'
					onClick={getExcelFile}
				/>
			</Tippy>
			{excelDropdownVisibility && (
				<div className='export-dropdown'>
					<div className='export-dropdown-header'>
						<div className='export-dropdown-header-left'>
							<p className='title'>Export excel :</p>
						</div>
						<div className='export-dropdown-header-right'>
							<FontAwesomeIcon
								icon={faTimes}
								className='close-icon'
								onClick={closeExcelDropdown}
							/>
						</div>
					</div>
					<div className='export-dropdown-content'>
						{excelStatus === 'loading' && (
							<p>
								<FontAwesomeIcon className='mr-5' icon={faSpinner} spin />{' '}
								Loading..
							</p>
						)}
						{excelStatus === 'success' && (
							<>
								<p>File is ready to download.</p>
								<button
									className='um-button um-button--primary mt-10'
									onClick={downloadExcelFile}
								>
									Download
								</button>
							</>
						)}
						{excelStatus === 'fail' && (
							<p>Something went wrong. Please try again.</p>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default UsersExport;
