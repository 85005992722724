import React, { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import DataTable from '../../components/data_table/DataTable';
import AddEditRightModal from '../../components/add_edit_right_modal/AddEditRightModal';

const tableHeaders = [
  {
    key: 'sno',
    label: 'S.NO',
    namespace: '',
    width: 70,
    filter: {
      'type': 'none',
    },
  },
  {
    key: 'label',
    label: 'Name',
    namespace: 'label',
    width: 400,
    filter: {
      'type': 'none',
    },
  },
  {
    key: 'enable',
    label: 'Enable',
    namespace: 'status',
    textAlign: 'center',
    width: 65,
    filter: {
      'type': 'none',
    },
  },
  {
    key: 'action',
    label: 'Action',
    namespace: 'action',
    width: 40,
    type: 'all',
    filter: {
      'type': 'none',
    },
  },
];

const initialState = {
  rightsData: {
    status: 'loading',
    list: [],
    pagination: {
      pageNumber: 0,
      count: 10,
      total: 0,
    },
  },
  isAddModal: true,
  showAddEditRightModal: false,
  editRightData: null,
}

const reducer = (state = initialState, action) => {
  let tempState = {...state};

  switch(action.type){
    case 'update_loading_status':
      tempState.rightsData.status = action.payload;
      break;
    case 'update_rights_data':
      tempState.rightsData.status = 'success';
      tempState.rightsData.list = action.payload;
      break;
    case 'update_add_edit_right_modal_visibility':
      tempState.showAddEditRightModal = action.payload.showAddEditRightModal;
      tempState.isAddModal = action.payload.isAddModal;
      tempState.editRightData = action.payload.editRightData;
      break;
    case 'update_edit_right_data_and_modal_visibility':
      tempState.showEditRightModal = action.payload.showEditRightModal;
      tempState.editRightData = action.payload.editRightData;
      break;
    default:
      break;
  }
  return tempState;
}

const Rights = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    getRights();
  }, []);

   const getRights = () => {
      dispatch({
        type: 'update_loading_status',
        payload: 'loading'
      })
      axios.get('/api/v1/rights/getall').then(response => {
        if (response.status === 200) {
          const data = response?.data ?? [];
          dispatch({
            type: 'update_rights_data',
            payload: data
          })
          reduxDispatch({
            type: 'UPDATE_RIGHTS_DATA',
            payload: data,
          });
        } else dispatch({
            type: 'update_rights_data',
            payload: []
          })
      }).catch(error => {
        console.log(error.response);
        dispatch({
            type: 'update_rights_data',
            payload: []
          })
      });
    };


  const openAddEditRightModal = () => dispatch({
    type: 'update_add_edit_right_modal_visibility',
    payload: {
      showAddEditRightModal: true,
      isAddModal: true,
      editRightData: null
    }
  });
  
  const closeAddEditRightModal = () => {
    dispatch({
        type: 'update_add_edit_right_modal_visibility',
        payload: {
          showAddEditRightModal: false,
          isAddModal: true,
          editRightData: null
        }
    })
  }

  const handleAction = (actionType, index) => {
    dispatch({
        type: 'update_add_edit_right_modal_visibility',
        payload: {
          showAddEditRightModal: true,
          isAddModal: false,
          editRightData: list?.[index] ?? []
        }
    })
  }

  const handleAddEditRightDataSubmit = () => {
    closeAddEditRightModal();
    getRights();
  }

  useEffect(()=>{
		if (window?.umApp?.DoOverrideRightsPage) {
			window?.umApp?.DoOverrideRightsPage();
		}
	},[])

  const {rightsData: { status, list }, isAddModal, showAddEditRightModal, editRightData} = state;

  return (
    <>
      <div className="text-right mb-15">
        <button className="um-button um-button--enter" onClick={openAddEditRightModal}>Add</button>
      </div>
      <DataTable
        loading={status === 'loading'}
        columnHeaders={tableHeaders}
        data={list}
        sortData={{}}
        onSortChange={data => console.log(data)}
        filterData={{}}
        onFilterChange={data => console.log(data)}
        disablePagination={true}
        actionType="edit"
        onAction={handleAction}
      />
      {
        showAddEditRightModal && (
          <AddEditRightModal
            isAdd={isAddModal}
            visible={showAddEditRightModal}
            data={editRightData}
            onSubmit={handleAddEditRightDataSubmit}
            onCancel={closeAddEditRightModal}
          />
        )
      }
    </>
  );
};

export default Rights;
