import React, {useEffect, useRef} from 'react';
import modulesLayout from './inner_components/modules_layout/index'

const ModulesModal = props => {
  const modalContentRef = useRef(null);
  const {title = '', visible = false, data, width = 650, onSubmit, onCancel,dropdownData} = props;

  // useEffect(() => {
  //   if (!visible) {
  //     document.body.style.overflow = 'unset';
  //     return;
  //   }

  //   document.body.style.overflow = 'hidden';

  //   // document.addEventListener('click', hideDropdownMenu);
  //   // return () => document.removeEventListener('click', hideDropdownMenu);
  // }, [visible]);

  // const hideDropdownMenu = event => {
  //   if (modalContentRef?.current?.contains(event.target) === false) {
  //     onCancel && onCancel();
  //   }
  // };

  const closeModal = e => {
    e && e.stopPropagation();
    onCancel && onCancel();
  };

  const modalContentStyles = {maxWidth: width};

  const getModulesLayout = () => {
    return modulesLayout({
      key: `edit-user`,
      component: `edit`,
      data,
      onSubmit,
      onCancel: closeModal,
      dropdownData
    });
  };

  useEffect(()=>{
    if (window?.umApp?.DoOverrideModulesModalComponent) {
			window?.umApp?.DoOverrideModulesModalComponent();
		}
  },[])

  return (
      <div className={visible ? 'um-modal show' : 'um-modal hide'}>
        <div
            className="um-modal-content"
            style={modalContentStyles}
            ref={modalContentRef}
        >
          <div className="um-modal-header">
            <p className="um-modal-header-text">{title} <span className="close" onClick={closeModal}>&times;</span></p>
          </div>
          <div className="um-modal-body">
            {getModulesLayout()}
          </div>
        </div>
      </div>
  );
};

export default ModulesModal;
