import React from 'react'
import DefaultEditUserLayout from "../default_edit_user_layout/DefaultEditUserLayout";
// import CustomEditUserLayout from './CustomEditUserLayout';

const Components  = {
    default: DefaultEditUserLayout,
    // 'edit-user': CustomEditUserLayout
}

export default block => {
  console.log(block.data);
    if (typeof Components[block.component] !== 'undefined') {
      return React.createElement(Components[block.component], {
        key: block.key,
        data: block.data,
        onSubmit: block.onSubmit,
        onCancel: block.onCancel,
      });
    }
    // component doesn't exist yet
    return React.createElement(Components['default'], {
        key: block.key,
        data: block.data,
        onSubmit: block.onSubmit,
        onCancel: block.onCancel,
    });
  }