import React from 'react';
import DefaultAddUserLayout from '../default_add_user_layout/DefaultAddUserLayout';
// import CustomAddUserLayout from './CustomAddUserLayout';

const Components = {
	default: DefaultAddUserLayout,
	// 'add-user': CustomAddUserLayout,
};

export default (block) => {
	// component does exist
	if (typeof Components[block.component] !== 'undefined') {
		return React.createElement(Components[block.component], {
			key: block.key,
			data: block.data,
			onSubmit: block.onSubmit,
			onCancel: block.onCancel,
		});
	}
	// component doesn't exist yet
	return React.createElement(Components['default'], {
		key: block.key,
		data: block.data,
		onSubmit: block.onSubmit,
		onCancel: block.onCancel,
	});
};
