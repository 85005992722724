import React, {useState, useEffect, useRef} from 'react';
import {Col, Row} from 'react-grid-system';
import { useForm } from "react-hook-form";
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { addAlertToDOM, capitalizeFirstLetter } from '../../helpers/util';
import { useSelector } from 'react-redux';

const ChangeUserPasswordModal = props => {
  const {modalTimeout} = useSelector(state => state.util);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showPassword, setPasswordVisibility] = useState();
  const [showConfirmPassword, setConfirmPasswordVisibility] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });

  const passwordRef = useRef({});
  passwordRef.current = watch("password", "");
  const modalContentRef = useRef(null);

  const {title = 'Change Password', visible = false, width = 650, data, onSubmit, onCancel} = props;

  useEffect(() => {
    if (!visible) {
      document.body.style.overflow = 'unset';
      return;
    }

    document.body.style.overflow = 'hidden';

    document.addEventListener('click', hideDropdownMenu);
    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('click', hideDropdownMenu)
    };
  }, [visible]);

  const hideDropdownMenu = event => {
    if (modalContentRef && modalContentRef.current.contains(event.target) === false) {
      onCancel && onCancel();
    }
  };

  const closeModal = e => {
    e && e.stopPropagation();
    onCancel && onCancel();
  };

  const handlePasswordVisibilityChange = () => setPasswordVisibility(prevState => !prevState);
  const handleConfirmPasswordVisibilityChange = () => setConfirmPasswordVisibility(prevState => !prevState);

  const submitData = formData => {
    const {password, confirmPassword} = formData;
    const {email} = data;
    setSubmitLoading(true);
    const requestPayload = {
        email,
        new_password: password,
        confirm_password: confirmPassword
    };

    axios.post('/api/v1/user/change/password', requestPayload).then(response => {
      if (response.status === 200) {
        addAlertToDOM('.cupl-alert-section', 'success', 'Password changed successfully', 'mb-15');
        onSubmit && setTimeout(() => onSubmit(), modalTimeout);
      } else addAlertToDOM('.cupl-alert-section', 'error', 'Something went wrong. Try again.', 'mb-15');
    }).catch(error => {
      const errorResponse = error?.response?.data ?? {};
      if(errorResponse.hasOwnProperty('error')){
        const errorData = errorResponse.error;
        console.log(errorData);
        if(errorData.hasOwnProperty('password_policy_errors')){
          const passwordErrors = errorData.password_policy_errors;
          console.log(passwordErrors);
          const buildPasswordPolicyErrors = () => {
            let errorHtml = '';

            passwordErrors.forEach(({error_msg}) => {
              errorHtml += `<li>${capitalizeFirstLetter(error_msg)}</li>`;
            });

            return errorHtml === '' ? '' : `<ul>${errorHtml}</ul>`;            
          };
          addAlertToDOM('.cupl-alert-section', 'error', `<p>Password policy not matched</p><ul>${buildPasswordPolicyErrors()}</ul>`, 'mb-15', 7000, true);
        } else if(errorData?.debug_msg === "New password can't be same as current password"){
          addAlertToDOM('.cupl-alert-section', 'error', 'New password can"t be same as current password', 'mb-15');
        } else addAlertToDOM('.cupl-alert-section', 'error', 'Something went wrong. Try again.', 'mb-15');
      } else addAlertToDOM('.cupl-alert-section', 'error', 'Something went wrong. Try again.', 'mb-15');
    }).finally(() => setSubmitLoading(false));
  }

  useEffect(()=>{
		if (window?.umApp?.DoOverrideChangeUserPasswordModalComponent) {
				window?.umApp?.DoOverrideChangeUserPasswordModalComponent();
			}
	},[])

  const modalContentStyles = {maxWidth: width};

  return (
      <div className={visible ? 'um-modal show' : 'um-modal hide'}>
        <div className="um-modal-content" style={modalContentStyles} ref={modalContentRef}>
          <div className="um-modal-header">
            <p className="um-modal-header-text">{title} <span className="close" onClick={closeModal}>&times;</span></p>
          </div>
          <div className="um-modal-body change-password-modal">
            <form onSubmit={submitLoading?()=>{}:handleSubmit(submitData)} noValidate>
              <div className="cupl-alert-section"/>
              <Row>
                <Col xs={6} className="mb-20">
                  <div className="inputPasswordWrapper">
                    <input 
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="um-input full-width"
                      placeholder="New Password"
                      {...register("password", {
                        required: true,
                      })}
                    />
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="password-visibility-icon" onClick={handlePasswordVisibilityChange} />
                  </div>
                  { errors?.password?.type === 'required' && <p className="validation-error">Password is required</p> }
                  { errors?.password?.type === 'pattern' && <p className="validation-error">Password must contain minimum eight characters, at least one uppercase, one lowercase, one number and one special character</p> }
                </Col>
                <Col xs={6} className="mb-20">
                  <div className="inputPasswordWrapper">
                    <input 
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        className="um-input full-width"
                        placeholder="Confirm Password"
                        {...register("confirmPassword", {
                          validate: value => value === passwordRef.current || "The passwords do not match"
                        })}
                    />
                    <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} className="password-visibility-icon" onClick={handleConfirmPasswordVisibilityChange} />
                  </div>
                  { errors?.confirmPassword?.type === 'validate' && <p className="validation-error">The passwords do not match</p> }
                </Col>
                <Col xs={12} className="text-right">
                  <button type='submit' className="um-button um-button--primary mr-10">
                    {
                      submitLoading ? (
                        <span>
                          <FontAwesomeIcon
                              className="loading-icon mr-5"
                              icon={faSpinner}
                              spin
                          />
                          Submit
                        </span>
                      ) : 'Submit'
                    }
                  </button>
                  <button
                      type="button"
                      className="um-button"
                      onClick={closeModal}
                  >Cancel
                  </button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
  );
};

export default ChangeUserPasswordModal;
