const initialState = {
  userData: null,
  rights: [],
  roles: [],
  groups: [],
  modules: [],
  modulesflat:[],
  modalTimeout:0,
  urlHistory:{prev:"",cur:""},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  let tempState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case 'UPDATE_RIGHTS_DATA':
      tempState.rights = action.payload;
      return tempState;
    case 'UPDATE_ROLES_DATA':
      tempState.roles = action.payload;
      return tempState;
    case 'UPDATE_GROUPS_DATA':
      tempState.groups = action.payload;
      return tempState;
    case 'UPDATE_MODULES_DATA':
      tempState.modules = action.payload.data;
      tempState.modulesflat = action.payload.dataflat;
      return tempState;
    case 'UPDATE_URL_HISTORY':
      tempState.urlHistory = {
        prev : tempState.urlHistory.cur,
        cur : action.payload
      };
      return tempState;
    default:
      return tempState;
  }
}
