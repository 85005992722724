const initialState = {
  userDataStatus: 'loading',
  userData: {},
};

const UserDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_user_data':
      return {
        ...state,
        userData: action.payload.userData,
        userDataStatus: action.payload.userDataStatus,
      };
    case 'update_user_data_status':
      return {
        ...state,
        userDataStatus: action.payload,
      };
    default:
      return state;
  }
};

export default UserDataReducer;
