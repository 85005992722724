import React, {useEffect, useReducer} from 'react';
import {useSelector} from 'react-redux';
import {Col, Row} from 'react-grid-system';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {addAlertToDOM} from '../../../../helpers/util';

const buildInitialState = data => {
  const {
    email = '',
    status = false,
    first_name: firstName = '',
    last_name: lastName = '',
    roles = [],
    groups = [],
  } = data ?? {};
  return {
    email,
    // status: status == 'enabled',
    status: status,
    firstName,
    lastName,
    role: roles!=null&&roles.length > 0 ? roles[0].id : '',
    group: groups!=null&&groups.length > 0 ? groups[0].id : '',
  };
};

const initialState = {
  userData: {
    email: '',
    status: true,
    firstName: '',
    lastName: '',
    role: '',
    group: '',
  },
  submitLoading: false,
};

const reducer = (state = initialState, action) => {
  let tempState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case 'UPDATE_EDIT_USER_DATA':
      tempState.userData = action.payload;
      break;
    case 'UPDATE_EDIT_USER_FIELDS_DATA':
      tempState.userData[action.payload.name] = action.payload.value;
      break;
    case 'RESET_EDIT_USER_DATA':
      tempState = initialState;
      break;
    case 'UPDATE_SUBMIT_LOADING_STATUS':
      tempState.submitLoading = action.payload;
      break;
    default:
      break;
  }
  return tempState;
};

const DefaultEditUserLayout = ({data, onSubmit, onCancel}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {roles, groups, modalTimeout} = useSelector(state => state.util);

  useEffect(() => {
    if (data) {
      dispatch({
        type: 'UPDATE_EDIT_USER_DATA',
        payload: buildInitialState(data),
      });
    }
  }, [data]);

  const handleEditUserFieldsChange = e => {
    const {name, type, value, checked} = e.target;
    dispatch({
      type: 'UPDATE_EDIT_USER_FIELDS_DATA',
      payload: {
        name,
        value: type === 'checkbox' ? checked === true : value,
      },
    });
  };

  const submitData = () => {
    dispatch({
      type: 'UPDATE_SUBMIT_LOADING_STATUS',
      payload: true,
    });
    const requestPayload = {
      'login_id': email,
      'email': email,
      'first_name': firstName,
      'last_name': lastName,
      'roles': role === '' ? [] : [role],
      add_on_details:{
				groups: group === '' ? [] : [group],
				// status: status === true ? 'enabled' : 'disabled',
        status: status,
        full_name : `${firstName} ${lastName}`,
			}
    };

    axios.put('/api/v1/user/edit', requestPayload).then(response => {
      if (response.status === 200) {
        addAlertToDOM('.deul-section', 'success', 'User data updated successfully', 'mb-15');
        onSubmit && setTimeout(() => onSubmit(), modalTimeout);
      } else addAlertToDOM('.deul-section', 'warning', 'Something went wrong. Try again.', 'mb-15');
    }).catch(error => {
      addAlertToDOM('.deul-section', 'warning', 'Something went wrong. Try again.', 'mb-15');
    }).finally(() => dispatch({
      type: 'UPDATE_SUBMIT_LOADING_STATUS',
      payload: false,
    }));
  };

  const handleCancel = e => {
    onCancel && onCancel(e);
    dispatch({
      type: 'RESET_EDIT_USER_DATA',
    });
  };

  const buildRoleOptions = roles.map(({_id, name}) => <option value={_id} key={_id}>{name}</option>);
  const buildGroupOptions = groups.map(({id, label}) => <option value={id} key={id}>{label}</option>);

  const {
    userData: {
      email,
      status,
      firstName,
      lastName,
      role,
      group,
    },
    submitLoading
  } = state;

  return (
    <>
    <div className="deul-section"/>
    <Row>
          <Col xs={6} className='mb-20'>
          <label for="email">Email :</label>
            <input
                name="email"
                id="email"
                type="email"
                className="um-input full-width"
                placeholder="Ex: john007@gmail.com"
                value={email}
                onChange={handleEditUserFieldsChange}
                readOnly
            />
          </Col>
          <Col xs={6} className='mb-20'>
            <label for="status">Status :</label>
            <div className="checkbox-wrapper">
              <input
                  id="status"
                  name="status"
                  checked={status}
                  type="checkbox"
                  onChange={handleEditUserFieldsChange}
              />
              <label className="cursor-pointer" htmlFor="status">Enable</label>
            </div>
          </Col>
          <Col xs={6} className='mb-20'>
            <label for="firstName">First Name :</label>
            <input
                name="firstName"
                id="firstName"
                type="text"
                className="um-input full-width"
                placeholder="Ex: John"
                value={firstName}
                onChange={handleEditUserFieldsChange}
            />
          </Col>
          <Col xs={6} className='mb-20'>
            <label for="lastName">Last Name :</label>
            <input
                name="lastName"
                id="lastName"
                type="text"
                className="um-input full-width"
                placeholder="Ex: john007"
                value={lastName}
                onChange={handleEditUserFieldsChange}
            />
          </Col>
          <Col xs={6} className='mb-20'>
            <label for="role">Role :</label>
            <select
                required name="role"
                className="um-select full-width"
                placeholder="Select Role"
                value={role}
                onChange={handleEditUserFieldsChange}
            >
              <option value="" disabled defaultValue hidden>Select Role</option>
              {buildRoleOptions}
            </select>
          </Col>
          <Col xs={6} className='mb-20'>
          <label for="group">Group :</label>
            <select
                required
                name="group"
                id="group"
                className="um-select full-width"
                placeholder="Role"
                value={group}
                onChange={handleEditUserFieldsChange}
            >
              <option value="" disabled defaultValue hidden>Select Group</option>
              {buildGroupOptions}
            </select>
          </Col>
          <Col xs={12} className="text-right">
            <button
                className="um-button um-button--primary mr-10"
                onClick={submitLoading?()=>{}:submitData}
            >
              {
                submitLoading ? (
                  <span>
                    <FontAwesomeIcon
                        className="loading-icon mr-5"
                        icon={faSpinner}
                        spin
                    />
                    Submit
                  </span>
                ) : 'Submit'
              }
            </button>
            <button
                className="um-button"
                onClick={handleCancel}
            >Cancel
            </button>
          </Col>
        </Row>
    </>
  );
};

export default DefaultEditUserLayout;
