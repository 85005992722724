import React, {useEffect, useRef} from 'react';
import './DTModal.scss';

const DTModal = ({title, visible, width = 650, onCancel, children}) => {
  const modalContentRef = useRef(null);

  useEffect(() => {
    if (!visible) {
      document.body.style.overflow = 'unset';
      return;
    }

    document.body.style.overflow = 'hidden';

    document.addEventListener('click', hideDropdownMenu);
    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('click', hideDropdownMenu)
    };
  }, [visible]);

  const hideDropdownMenu = event => {
    if (modalContentRef && modalContentRef?.current?.contains(event?.target) ===
        false) {
      onCancel && onCancel();
    }
  };

  const closeModal = e => {
    e.stopPropagation();
    onCancel && onCancel();
  };

  const modalContentStyles = {maxWidth: width};

  return (
      <div className={visible ? 'um-modal show' : 'um-modal hide'}>
        <div className="um-modal-content" style={modalContentStyles}
             ref={modalContentRef}>
          <div className="um-modal-header">
            <h3 className="um-modal-header-text">{title} <span className="close"
                                                               onClick={closeModal}>&times;</span>
            </h3>
          </div>
          <div className="um-modal-body">
            {children}
          </div>
        </div>
      </div>
  );
};

export default DTModal;
