import React, {useEffect, useReducer} from 'react';
import {useSelector} from 'react-redux';
import {Col, Row} from 'react-grid-system';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {addAlertToDOM} from '../../../../helpers/util';


const buildInitialState = data => {
  const {
    _id="",
    parent_id ="",
    new_parent_id ="",
    key="",
    label="",
    child=[],
    is_leaf=false
  } = data ?? {};

  let disabledOptions ={}
  if (data._id) {
    disabledOptions[data._id]= true
    const processChild=(child)=>{
      child.forEach(dt => {
        disabledOptions[dt._id]= true
        if (dt.child.length>0) {
          processChild(dt.child)
        }
      });
    }
    if (data.child.length>0) {
      processChild(data.child)
    }
  }


  return [{
    parent_id,
    new_parent_id,
    _id,
    key,
    label,
    child,
    is_leaf,
  },disabledOptions];
};

const initialState = {
  moduleData: {
    _id:"",
    parent_id :"",
    new_parent_id :"",
    key:"",
    label:"",
    child:[],
    is_leaf:false
  },
  disabledOptions:{},
  submitLoading: false
};

const reducer = (state = initialState, action) => {
  let tempState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case 'UPDATE_MODULES_DATA':
      tempState.moduleData = action.payload;
      break;
    case 'UPDATE_MODULES_DATA_AND_DISABLED_OPTIONS':
      tempState.moduleData = action.payload[0];
      tempState.disabledOptions = action.payload[1];
      break;
    case 'UPDATE_MODULES_FIELDS_DATA':
      tempState.moduleData[action.payload.name] = action.payload.value;
      break;
    case 'RESET_MODULES_DATA':
      tempState = initialState;
      break;
    case 'UPDATE_SUBMIT_LOADING_STATUS':
      tempState.submitLoading = action.payload;
      break;
    default:
      break;
  }
  return tempState;
};


const DefaultModulesLayout = ({data, onSubmit, onCancel,dropdownData}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const {modulesflat} = useSelector(state => state.util);
  const {modalTimeout} = useSelector(state => state.util);


  useEffect(() => {
    if (data) {
      dispatch({
        type: 'UPDATE_MODULES_DATA_AND_DISABLED_OPTIONS',
        payload: buildInitialState(data),
      });
    }
  }, [data]);

  const handleModulesFieldsChange = e => {
    const {name, type, value, checked} = e.target;
    dispatch({
      type: 'UPDATE_MODULES_FIELDS_DATA',
      payload: {
        name,
        value: type === 'checkbox' ? checked == true : value,
      },
    });
  };

  const submitData = () => {
    if (label.trim()=="") {
      addAlertToDOM('.deul-section', 'warning', 'Module Name should be fill', 'mb-15');
       return
    }
    dispatch({
      type: 'UPDATE_SUBMIT_LOADING_STATUS',
      payload: true,
    });

    if (key == "") {
      const requestPayload = {
        "parent_id":new_parent_id==""?null:new_parent_id,
        "key" : label.toLowerCase().trim().replaceAll(" ","-"),
        label,
        child
      };

      axios.post('/api/v1/modules/add', requestPayload).then(response => {
        if (response.status === 200) {
            addAlertToDOM('.deul-section', 'success', 'Module data added successfully', 'mb-15');
            onSubmit && setTimeout(() => onSubmit(), modalTimeout);
        } else addAlertToDOM('.deul-section', 'warning', 'Something went wrong. Try again.', 'mb-15');
      }).catch(error => {
        addAlertToDOM('.deul-section', 'warning', 'Something went wrong. Try again.', 'mb-15');
      }).finally(() => dispatch({
        type: 'UPDATE_SUBMIT_LOADING_STATUS',
        payload: false,
      }));
    }else{
      let requestPayload = {
        _id,
        label,
        child
      };
      if (new_parent_id!=parent_id) {
        requestPayload.new_parent_id = new_parent_id==""?null:new_parent_id
        requestPayload.parent_id = parent_id ==""?null:parent_id
      }

      axios.put('/api/v1/modules/edit', requestPayload).then(response => {
        if (response.status === 200) {
          addAlertToDOM('.deul-section', 'success', 'Module data updated successfully', 'mb-15');
          onSubmit && setTimeout(() => onSubmit(), modalTimeout);
        } else addAlertToDOM('.deul-section', 'warning', 'Something went wrong. Try again.', 'mb-15');
      }).catch(error => {
        addAlertToDOM('.deul-section', 'warning', 'Something went wrong. Try again.', 'mb-15');
      }).finally(() => dispatch({
        type: 'UPDATE_SUBMIT_LOADING_STATUS',
        payload: false,
      }));
    }
    
  };

  const handleCancel = e => {
    console.log('triggered');
    onCancel && onCancel(e);
    dispatch({
      type: 'RESET_MODULES_DATA',
    });
  };

  const createHyphen =(n)=>{
    let hyphen=""
    for (let i = 0; i < n; i++) {
      hyphen +="- " ;
    }
    return hyphen
  }

  const buildModulesOptions = dropdownData.map(
    ({_id,key, label, level}) => <option disabled={state.disabledOptions[_id]} value={_id} key={_id+key}>{createHyphen(level)}{label}</option>
    
  );


  // const buildModulesOptions = dropdownData.map(({_id,key, label}) => <option value={_id} key={key}>{label}</option>);

  const {
    moduleData: {
      parent_id,
      new_parent_id,
      key,
      label,
      child,
      is_leaf,
      _id
    },
    disabledOptions,
    submitLoading
  } = state;

  return (
    <div className="deul-section">
    <Row>
      <Col xs={6} className='mb-20'>
        <input
            name="label"
            type="label"
            className="um-input full-width"
            placeholder="Module Name"
            value={label}
            onChange={handleModulesFieldsChange}
        />
      </Col>

      <Col xs={6} className='mb-20'>
            <select
                required name="new_parent_id"
                className="um-select full-width"
                placeholder="Level"
                value={new_parent_id}
                onChange={handleModulesFieldsChange}
            >
              <option value="" defaultValue >Root Level</option>
              {buildModulesOptions}
            </select>
          </Col>
      
      <Col xs={12} className="text-right">
        <button
            className="um-button um-button--primary mr-10"
            onClick={submitLoading?()=>{}:submitData}
        >
          {
            submitLoading ? (
              <span>
                <FontAwesomeIcon
                    className="loading-icon mr-5"
                    icon={faSpinner}
                    spin
                />
                Submit
              </span>
            ) : 'Submit'
          }
        </button>
        <button
            className="um-button"
            onClick={handleCancel}
        >Cancel
        </button>
      </Col>
    </Row>
    </div>
  );
};

export default DefaultModulesLayout;
