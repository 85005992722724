import React, {useState, useEffect, useRef} from 'react';
import {Col, Row} from 'react-grid-system';
import { useForm } from "react-hook-form";
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { addAlertToDOM, capitalizeFirstLetter } from '../../helpers/util';
import { useSelector } from 'react-redux';

const DeleteUserModal = props => {
	const modalContentRef = useRef(null);
	const {
		title = 'Comfirm Delete User',
		visible = false,
		data,
		width = 650,
		onSubmit,
		onCancel,
	} = props;

	useEffect(() => {
		if (!visible) {
			document.body.style.overflow = 'unset';
			return;
		}

		document.body.style.overflow = 'hidden';

		document.addEventListener('click', hideDropdownMenu);
		return () => {
			document.body.style.overflow = 'unset';
			document.removeEventListener('click', hideDropdownMenu);
		};
	}, [visible]);

  const modalContentStyles = { maxWidth: width };
  const {modalTimeout} = useSelector(state => state.util);
  const [submitLoading, setSubmitLoading] = useState(false);

	const hideDropdownMenu = (event) => {
		if (modalContentRef?.current?.contains(event.target) === false) {
			// onCancel && onCancel();
		}
	};

	const closeModal = (e) => {
		e && e.stopPropagation();
		onCancel && onCancel();
	};

	const submitData = () => {
		const {email} = data;
		setSubmitLoading(true);
		const requestPayload = {
			data : {email}
		};

		axios.delete('/api/v1/user/delete', requestPayload).then(response => {
		if (response.status === 200) {
			addAlertToDOM('.cupl-alert-section', 'success', 'User deleted successfully', 'mb-15');
			onSubmit && setTimeout(() => onSubmit(), modalTimeout);
		} 
		//   else addAlertToDOM('.cupl-alert-section', 'error', 'Something went wrong. Try again.', 'mb-15');
		}).catch(error => {
			const {response} = error
			const {data, status, statusText} = response
			const errorResponse = data ?? {};
			if (errorResponse.hasOwnProperty('error')) {
				const errorData = errorResponse.error;
				console.log(errorData);
				if (errorData?.debug_msg.includes("is owner of")) {
					addAlertToDOM(
						'.um-modal-body .cupl-alert-section',
						'error',
						`<b>${props.data.username} user cannot be deleted.</b> <span style="color: #3e3e3e">${errorData.debug_msg}</span>`,
						'mb-15',
						10000,
						true,
						true
					);
				} else{
					addAlertToDOM(
						'.um-modal-body .cupl-alert-section',
						'error',
						'Something went wrong. Try again.',
						'mb-15'
					);
				}
			}else if(status == 403){
				addAlertToDOM(
					'.cupl-alert-section',
					'error',
					`${error.message} (${statusText})`,
					'mb-15'
				);
			} else{
				addAlertToDOM(
					'.um-modal-body .cupl-alert-section',
					'error',
					'Something went wrong. Try again.',
					'mb-15'
				);
			}
		}).finally(() => setSubmitLoading(false));
	}

	useEffect(()=>{
		if (window?.umApp?.DoOverrideDeleteUserModalComponent) {
				window?.umApp?.DoOverrideDeleteUserModalComponent();
			}
	},[])

	return (
		<div className={visible ? 'um-modal show' : 'um-modal hide'}>
			<div
				className='um-modal-content'
				style={modalContentStyles}
				ref={modalContentRef}
			>
				<div className='um-modal-header'>
					<p className='um-modal-header-text'>
						{title}{' '}
						<span className='close' onClick={closeModal}>
							&times;
						</span>
					</p>
				</div>
				<div className='um-modal-body'>
					<div className="cupl-alert-section"></div>
					<Row>
						<Col xs={12}>Are you sure you would like to delete {data?.username}?</Col>
						<Col xs={12} className="text-right">
							<button type='button' onClick={submitLoading?()=>{}:submitData}  className="um-button um-button--primary mr-10">
							{
								submitLoading ? (
								<span>
									<FontAwesomeIcon
										className="loading-icon mr-5"
										icon={faSpinner}
										spin
									/>
									Submit
								</span>
								) : 'Submit'
							}
							</button>
							<button
								type="button"
								className="um-button"
								onClick={closeModal}
							>Cancel
							</button>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default DeleteUserModal;

