import React from 'react';
import DefaultHeaderLayout from './default/Defaultheader';
// import CustomHeaderLayout from './custom/Customheader'

const Components = {
	default: DefaultHeaderLayout,
	// 'header-layout': CustomHeaderLayout,
};

export default (block) => {
	// component does exist
	if (typeof Components[block.component] !== 'undefined') {
		return React.createElement(Components[block.component], {
			key: block.key,
			data: block.data
		});
	}
	// component doesn't exist yet
	return React.createElement(Components['default'], {
		key: block.key,
		data: block.data
	});
};