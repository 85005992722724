import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const { groups: { title: groupsTitle = 'Group' } = {} } = window['runConfig'];

const initialState = {
	role: '',
	group: '',
	filterName: '',
};

const UsersFilter = ({ onFilter }) => {
	const [filterData, setFilterData] = useState(initialState);
	const { roles, groups } = useSelector((state) => state.util);
	const { role, group, filterName } = filterData;

	const handleFilterFieldChange = (e) => {
		const { name, value } = e.target;
		setFilterData((preData) => {
			return {
				...preData,
				[name]: value,
			};
		});
	};

	const handleFilter = () => {
		onFilter &&
			onFilter({
				role,
				group,
				filterName,
			});
	};

	const handleClearFilter = () => {
		setFilterData(initialState);
		onFilter && onFilter(initialState);
	};

	const buildRoleOptions =
		Array.isArray(roles) && roles.length > 0
			? roles.map(({ _id, name }) => (
					<option value={_id} key={_id}>
						{name}
					</option>
			  ))
			: null;
	const buildGroupOptions =
		Array.isArray(groups) && groups.length > 0
			? groups.map(({ key, label }) => (
					<option value={key} key={key}>
						{label}
					</option>
			  ))
			: null;

	return (
		<>
			<select
				name='role'
				required
				className='um-select users-filter-role-select'
				placeholder='Role'
				value={role}
				onChange={handleFilterFieldChange}
			>
				<option value='' disabled defaultValue hidden>
					Role
				</option>
				{buildRoleOptions}
			</select>
			<select
				name='group'
				required
				className='um-select users-filter-group-select'
				placeholder={groupsTitle}
				value={group}
				onChange={handleFilterFieldChange}
			>
				<option value='' disabled defaultValue hidden>
					{groupsTitle}
				</option>
				{buildGroupOptions}
			</select>
			<input
				name='filterName'
				className='um-input users-filter-search-input'
				placeholder='Search user'
				value={filterName}
				onChange={handleFilterFieldChange}
			/>
			<button
				className='um-button um-button--primary mr-10'
				onClick={handleFilter}
			>
				Filter
			</button>
			<button className='um-button' onClick={handleClearFilter}>
				Clear
			</button>
		</>
	);
};

export default UsersFilter;
