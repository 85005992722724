import React, {useEffect, useReducer} from 'react';
import axios from 'axios';
import DataTable from '../../components/data_table/DataTable';
import RoleModal from '../../components/role-modal/RoleModal';
import {isEmptyObject} from '../../helpers/util';
import { useDispatch, useSelector } from 'react-redux';

const tableHeaders = [
  {
    key: 'name',
    label: 'Role Name',
    namespace: 'name',
    width: 70,
    visible: true,
    sortable: false,
    filter: {
      'type': 'none',
      'trueBackendType': 'string',
    },
  },
  {
    key: 'action',
    label: 'Action',
    namespace: 'action',
    visible: true,
    width: 30,
    type: 'all',
    filter: {
      'type': 'none',
    },
  },
];

const initialState = {
  filterData: {
    role: '',
    group: '',
    searchString: '',
  },
  sortData: {},
  roleData: {
    status: 'loading',
    list: [],
    pagination: {
      pageNumber: 0,
      count: 10,
      total: 0,
    },
  },
  showRoleModal: false,
  editRoleData: null,
};

const reducer = (state = initialState, action) => {
  let tempState = {...state};
  switch (action.type) {
    case 'UPDATE_ROLE_DATA':
      tempState.roleData.status = 'success';
      tempState.roleData.list = action.payload;
      break;
    case 'UPDATE_ROLE_DATA_TOTAL_COUNT':
      tempState.roleData.status = 'success';
      tempState.roleData.list = action.payload;
      break;
    case 'UPDATE_ROLE_DATA_STATUS':
      tempState.roleData.status = action.payload;
      break;
    case 'UPDATE_SORT_DATA':
      tempState.sortData = action.payload;
      break;
    case 'UPDATE_FILTER_DATA':
      tempState.filterData = action.payload;
      break;
    case 'UPDATE_ROLE_MODAL_VISIBILITY':
      tempState.showRoleModal = action.payload;
      break;
    case 'UPDATE_ROLE_DATA_AND_MODAL':
      tempState.showRoleModal = action.payload.showRoleModal;
      tempState.editRoleData = action.payload.editRoleData;
      break;
    case 'UPDATE_PAGINATION_DATA':
      tempState.roleData.pagination = action.payload;
      break;
    case 'UPDATE_TOTAL_COUNT':
      tempState.roleData.pagination.total = action.payload;
      break;
    case 'RESET_SORT_PAGINATION':
      tempState.roleData.pagination.total = 0;
      tempState.roleData.pagination.pageNumber = 0;
      tempState.sortData = {};
      break;
    default:
      break;
  }
  return tempState;
};

const Roles = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const { urlHistory} = useSelector((state) => state.util);
  const {
    sortData,
    roleData: {status, list, pagination},
    showRoleModal,
    editRoleData, 
  } = state;
  const { pageNumber, count } = pagination;

  useEffect(() => {
    getTotalRecordsCount();
  }, []);


  useEffect(() => {
    if (Object.keys(sortData).length > 0) {
      getRoleList();
    }
  }, [sortData]);

  useEffect(() => {
    getRoleList();
  }, [pageNumber,count]);

  useEffect(()=>{
		const {prev,cur} = urlHistory
		if (prev !== cur) {
			if (pageNumber != 0  || Object.keys(sortData).length > 0) {
				dispatch({
					type: 'RESET_SORT_PAGINATION',
				});
			}
			
		}
	},[urlHistory])

  const serialize = (obj, prefix) => {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

  const getRoleList = () => {
    dispatch({
      type: 'UPDATE_ROLE_DATA_STATUS',
      payload: 'loading',
    });

    const requestPayload = {};

    if (!isEmptyObject(sortData)) {
      let tempSortData = {...sortData};
      
      if(tempSortData.hasOwnProperty('name')){
        tempSortData.name = tempSortData.name;
      }
      requestPayload.query = {
        '$sort': tempSortData,
      };
      delete tempSortData.name;
    }

    requestPayload.page = pageNumber
    requestPayload.size = count

    axios.get('/api/v1/roles/getall?'+serialize(requestPayload)).then(response => {
      if (response.status === 200) {
        // console.log(response.data.data);
        dispatch({
          type: 'UPDATE_ROLE_DATA',
          payload: response?.data?.data ?? [],
        });
      } else dispatch({
        type: 'UPDATE_ROLE_DATA',
        payload: [],
      });
    }).catch(error => {
      dispatch({
        type: 'UPDATE_Role_DATA',
        payload: [],
      });
    });
  };

  const updateGlobalData = () => {
    axios.get('/api/v1/roles/getall').then(response => {
      if (response.status === 200) {
        const data = response?.data?.data ?? [];
        reduxDispatch({
          type: 'UPDATE_ROLES_DATA',
          payload: data,
        });
      }
    }).catch(error => {
      console.log(error.response);
    });
  };

  const getTotalRecordsCount = () => {
    axios
      .get('/api/v1/roles/get/count')
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: 'UPDATE_TOTAL_COUNT',
            payload: response?.data?.data ?? 0,
          });
          updateGlobalData()
        } else
          dispatch({
            type: 'UPDATE_TOTAL_COUNT',
            payload: 0,
          });
      })
      .catch((error) => {
        console.log(error.response);
        dispatch({
          type: 'UPDATE_TOTAL_COUNT',
          payload: 0,
        });
      });
  };

  const handleSortChange = data => {
    let tempSortData = {...data};
    const currColumnKey = Object.keys(data)[0];

    if (tempSortData.hasOwnProperty(currColumnKey)) {
      if (data[currColumnKey] === 0) {
        delete tempSortData[currColumnKey];
      } else {
        tempSortData[currColumnKey] = data[currColumnKey];
      }
    } else tempSortData[currColumnKey] = data[currColumnKey];

    dispatch({
      type: 'UPDATE_SORT_DATA',
      payload: tempSortData,
    });
  };

  const handleAction = (type, index) => {
    dispatch({
      type: 'UPDATE_ROLE_DATA_AND_MODAL',
      payload: {
        editRoleData: list[index],
        showRoleModal: true,
      },
    });
  };

  const openAddRoleModal = () => {
    dispatch({
      type: 'UPDATE_ROLE_DATA_AND_MODAL',
      payload: {
        editRoleData: null,
        showRoleModal: true,
      },
    });
  };

  const handleRoleSubmit = () => {
    dispatch({
      type: 'UPDATE_ROLE_DATA_AND_MODAL',
      payload: {
        editRoleData: null,
        showRoleModal: false,
      },
    });
    getTotalRecordsCount()
    getRoleList();
  };

  const closeRoleModal = () => {
    console.log("COming");
    dispatch({
      type: 'UPDATE_ROLE_DATA_AND_MODAL',
      payload: {
        editRoleData: null,
        showRoleModal: false,
      },
    });
  };

  const handlePaginationChange = (data) => {
		dispatch({
			type: 'UPDATE_PAGINATION_DATA',
			payload: data,
		});
	};
  
  useEffect(()=>{
		if (window?.umApp?.DoOverrideRolesPage) {
			window?.umApp?.DoOverrideRolesPage();
		}
	},[])

  return (
    <>
      <div className="text-right">
          <button
              className="um-button um-button--enter mb-15"
              onClick={openAddRoleModal}
          >Add Role
          </button>
      </div>
      <DataTable
          loading={status === 'loading'}
          columnHeaders={tableHeaders}
          data={list}
          sortData={sortData}
          onSortChange={handleSortChange}
          actionType="edit"
          onAction={handleAction}
          pagination={pagination}
          onPaginationChange={handlePaginationChange}
      />
      {
        showRoleModal && (
          <RoleModal
              width={'80%'}
              title={editRoleData?"Edit Role":"Add Role"}
              visible={showRoleModal}
              data={editRoleData}
              onSubmit={handleRoleSubmit}
              onCancel={closeRoleModal}
          />
        )
      }
    </>
);
};

export default Roles;
