import React from 'react';
import AceEditor from 'react-ace';
import ace from 'ace-builds';
import PropTypes from 'prop-types';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-kuroir';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/kuroir.css';

ace.config.set('useStrictCSP', true);

const CustomCodeEditor = ({ value, className, height, onChange }) => {
	const parsedValue =
		typeof value === 'object' ? JSON.stringify(value, null, '\t') : value;

	return (
		<AceEditor
			className={className}
			mode='json'
			theme='kuroir'
			value={parsedValue}
			onChange={(data) => onChange && onChange(data)}
			name='um-json-editor'
			width='100%'
			height={height}
			fontSize={14}
			editorProps={{ $blockScrolling: true }}
			setOptions={{
				enableBasicAutocompletion: true,
				enableLiveAutocompletion: true,
				enableSnippets: true,
			}}
		/>
	);
};

CustomCodeEditor.propTypes = {
	value: PropTypes.any.isRequired,
	className: PropTypes.string,
	height: PropTypes.string,
	onChange: PropTypes.func,
};

CustomCodeEditor.defaultProps = {
	value: '',
	className: '',
	height: '150px',
};

export default CustomCodeEditor;
