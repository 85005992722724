import React, { useState } from 'react';
import { faEdit, faKey, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import ShowMoreText from 'react-show-more-text';
import noDataImage from '../../../../assets/images/nodata.png';
import {
	calculateFixedWidthForColumn,
	getCalculatedTypeAndSubType,
	getPropByString,
} from '../../../../helpers/util';
import { CELL_WRAP_TYPE_CONSTANTS } from '../../constants';
import CustomCodeEditor from '../custom_code_editor/CustomCodeEditor';
import DTModal from '../dt-modal/DTModal';

const { WRAP_SCROLL_Y, CLIP, DYNAMIC, WRAP_SCROLL_X } =
	CELL_WRAP_TYPE_CONSTANTS;

const DataTableRows = (props) => {
	const [modalData, setModalData] = useState({
		isModalVisible: false,
		selectedDataField: '',
		modalContent: '',
	});
	const { isModalVisible, selectedDataField, modalContent } = modalData;
	const {
		loading = false,
		data,
		columnHeaders,
		rowCount,
		currPageStartValue,
		actionType,
		onAction,
	} = props;

	if (loading) {
		let i,
			tempRows = [];

		for (i = 0; i < rowCount; i++) {
			tempRows.push(
				<div className='dt-tr' key={i}>
					{
						// eslint-disable-next-line no-loop-func
						columnHeaders.map((colHeader, rowTdIndex) => {
							const { key, fixed } = colHeader;
							const tdUniqueKey = `${key}-${i}${rowTdIndex}`;

							const getTdClassNames = () => {
								let classNames = 'dt-td loading wrap-dynamic';
								if (fixed === true) {
									classNames += ' fixed';
								}
								return classNames;
							};

							return (
								<div
									className={getTdClassNames()}
									style={
										fixed === true
											? {
													left:
														rowTdIndex === 0
															? 0
															: calculateFixedWidthForColumn(
																	rowTdIndex,
																	columnHeaders
															  ),
											  }
											: {}
									}
									key={tdUniqueKey}
								>
									<div className='bar' />
								</div>
							);
						})
					}
				</div>
			);
		}

		return tempRows;
	}

	if (typeof data === 'undefined' || data === null || data.length === 0) {
		return (
			<div className='dt-tbody-empty'>
				<img className='no-data-image' src={noDataImage} alt='No data found' />
				<p className='no-data-text'>No records found</p>
			</div>
		);
	}

	const closeModal = () => {
		setModalData({
			isModalVisible: false,
			selectedDataField: '',
			modalContent: '',
		});
	};

	return (
		<>
			{Array.isArray(data) && data.length > 0
				? data.map((row, rowIndex) => (
						<div className='dt-tr' key={rowIndex}>
							{columnHeaders.map((colHeader, rowTdIndex) => {
								const {
									key,
									namespace,
									fixed,
									wrapType = 'wrap-dynamic',
									filter: { type = '', subType = '' },
								} = colHeader;

								if (key === 'enable') {
									const tdValue =
										namespace === ''
											? currPageStartValue + rowIndex + 1
											: getPropByString(row, namespace);
									const isChecked =
										typeof tdValue === 'number'
											? tdValue === 1
											: tdValue == 'enabled';

									return (
										<div className={'dt-td text-center'} key={rowTdIndex}>
											<input type='checkbox' checked={isChecked} readOnly />
										</div>
									);
								} else if (key === 'status') {
									const tdValue =
										namespace === ''
											? currPageStartValue + rowIndex + 1
											: getPropByString(row, namespace);
									return (
										<div
											className={`dt-td ${
												tdValue == true ? 'status-active' : 'status-inactive'
											}`}
											key={rowTdIndex}
										>
											{tdValue === true ? 'Active' : 'Inactive'}
										</div>
									);
								} else if (key === 'action') {
									const handleEditClick = () => {
										onAction && onAction('edit', rowIndex);
									};

									const handleDeleteClick = () => {
										onAction && onAction('delete', rowIndex);
									};

									const handleChangePasswordClick = () => {
										onAction && onAction('change-password', rowIndex);
									};

									const getActionIcons = () => {
										const editIcon = (
											<>
												<Tippy content='Edit'>
													<span>
														<FontAwesomeIcon
															icon={faEdit}
															className='cursor-pointer mr-10'
															onClick={handleEditClick}
														/>
													</span>
												</Tippy>
											</>
										);

										const changePasswordIcon = (
											<>
												<Tippy content='Change Password'>
													<span>
														<FontAwesomeIcon
															icon={faKey}
															className='cursor-pointer'
															onClick={handleChangePasswordClick}
														/>
													</span>
												</Tippy>
											</>
										);

										const deleteIcon = (
											<>
												<Tippy content='Delete'>
													<span>
														<FontAwesomeIcon
															icon={faTrash}
															className='cursor-pointer ml-10'
															onClick={handleDeleteClick}
														/>
													</span>
												</Tippy>
											</>
										);

										switch (actionType) {
											case 'edit':
												return editIcon;
											case 'change-password':
												return changePasswordIcon;
											case 'none':
												return null;
											default:
												return (
													<>
														{editIcon}
														{changePasswordIcon}
														{deleteIcon}
													</>
												);
										}
									};

									return (
										<div className='dt-td text-center' key={rowTdIndex}>
											{getActionIcons()}
										</div>
									);
								} else {
									const { calculatedType, calculatedSubType } =
										getCalculatedTypeAndSubType(type, subType);

									const tdValue =
										namespace === ''
											? currPageStartValue + rowIndex + 1
											: getPropByString(row, namespace);
									const tdUniqueKey = `${key}-${rowIndex}${rowTdIndex}`;

									if (typeof tdValue === 'object') {
										const showObjectDataInModal = () => {
											setModalData({
												isModalVisible: true,
												selectedDataField: key,
												modalContent: tdValue,
											});
										};

										const tdClasses =
											fixed === true
												? `dt-td ${calculatedType} ${calculatedSubType} wrap-dynamic fixed link`
												: `dt-td ${calculatedType} ${calculatedSubType} wrap-dynamic link`;

										return (
											<div
												className={tdClasses}
												style={
													fixed === true
														? {
																left:
																	rowTdIndex === 0
																		? 0
																		: calculateFixedWidthForColumn(
																				rowTdIndex,
																				columnHeaders
																		  ),
														  }
														: {}
												}
												data-class={key}
												key={tdUniqueKey}
												onClick={showObjectDataInModal}
											>
												View Data
											</div>
										);
									} else if (wrapType === CLIP) {
										return (
											<div
												className={
													fixed === true
														? `dt-td ${calculatedType} ${calculatedSubType} word-break fixed`
														: `dt-td ${calculatedType} ${calculatedSubType} word-break`
												}
												style={
													fixed === true
														? {
																left:
																	rowTdIndex === 0
																		? 0
																		: calculateFixedWidthForColumn(
																				rowTdIndex,
																				columnHeaders
																		  ),
														  }
														: {}
												}
												key={tdUniqueKey}
												data-class={key}
											>
												<ShowMoreText
													lines={1}
													more='more'
													less='less'
													className='content-css'
													anchorClass='my-anchor-css-class'
													expanded={false}
												>
													{tdValue}
												</ShowMoreText>
											</div>
										);
									} else {
										const getTdClassNames = () => {
											let classNames = `dt-td ${calculatedType} ${calculatedSubType} `;

											if (fixed === true) {
												classNames += 'fixed ';
											}

											if (wrapType === WRAP_SCROLL_X) {
												classNames += 'wrap-scroll-x';
											} else if (wrapType === WRAP_SCROLL_Y) {
												classNames += 'wrap-scroll-y';
											} else if (wrapType === DYNAMIC) {
												classNames += 'dynamic';
											} else classNames += 'wrap-dynamic';

											return classNames;
										};

										return (
											<div
												className={getTdClassNames()}
												style={
													fixed === true
														? {
																left:
																	rowTdIndex === 0
																		? 0
																		: calculateFixedWidthForColumn(
																				rowTdIndex,
																				columnHeaders
																		  ),
														  }
														: {}
												}
												key={tdUniqueKey}
												data-class={key}
											>
												{wrapType === WRAP_SCROLL_Y ? (
													<div className='wrap-scroll-y-inner'>{tdValue}</div>
												) : (
													tdValue
												)}
											</div>
										);
									}
								}
							})}
						</div>
				  ))
				: null}
			<DTModal
				title={`Data Field: ${selectedDataField}`}
				visible={isModalVisible}
				onCancel={closeModal}
			>
				<CustomCodeEditor value={modalContent} height='300px' />
			</DTModal>
		</>
	);
};

export default DataTableRows;
