import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUserCircle,
} from '@fortawesome/free-solid-svg-icons';

const DefaultHeaderLayout = () => {
	const {userData } = useSelector((state) => state.userData);

	const getUserFullName = () => {
		const { first_name = '', last_name = '' } = userData ?? {};
		let name = 'Username';
		if (first_name) {
			name = first_name;
			if (last_name) {
				name += ` ${last_name}`;
			}
		}
		return `${name}`;
	};

	const getUserRole = () => {
		const { roles } = userData ?? {};
		if (roles && roles.length === 0) {
			return 'Unknown';
		} else if (roles && roles.length === 1) {
			return roles?.[0]?.label ?? 'Unknown';
		} else return 'Unknown';
	};

	const handleLogout = () => {
		if (window?.umApp?.logout) {
			window?.umApp?.logout();
		} else alert('Please configure logout functionality');
	};

	const {
		mainApp: { logoUrl },
	} = window['runConfig'];

	return (
		<div className='app-header-wrapper'>
			<div className='app-brand'>
				<img src={logoUrl} width='100' alt='User Management'></img>
			</div>
			<div className='app-content-header'>
				<div className='user-dropdown'>
					<FontAwesomeIcon
						title='User'
						icon={faUserCircle}
						aria-label='user'
						className='user-icon cursor-pointer'
					/>
					<div className='user-dropdown-content'>
						<div className='user-detail-section'>
							<p className='user-name'>{getUserFullName()}</p>
							<p className='user-role'>{getUserRole()}</p>
						</div>
						<div className='links-section'>
							<p className='cursor-pointer' onClick={handleLogout}>
								Logout
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DefaultHeaderLayout;
