import React, {useEffect, useReducer} from 'react';
import { Table } from '../../components/expandable-table/Table';
import axios from 'axios';
import ModulesModal from '../../components/modules_modal/ModulesModal';
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import './Modules.scss';
import { useSelector } from 'react-redux';

const initialState = {
  filterData: {
    modules: '',
    group: '',
    searchString: '',
  },
  sortData: {},
  modulesData: {
    status: 'loading',
    list: [],
    flat:[],
    pagination: {
      pageNumber: 0,
      count: 10,
      total: 0,
    },
  },
  showModulesModal: false,
  editModulesData: null,
};

const reducer = (state = initialState, action) => {
  let tempState = {...state};
  switch (action.type) {
    case 'UPDATE_MODULES_DATA':
      tempState.modulesData.status = 'success';
      tempState.modulesData.list = action.payload.data;
      tempState.modulesData.flat = action.payload.dataflat
      break;
    case 'UPDATE_MODULES_DATA_TOTAL_COUNT':
      tempState.modulesData.status = 'success';
      tempState.modulesData.list = action.payload;
      break;
    case 'UPDATE_MODULES_DATA_STATUS':
      tempState.modulesData.status = action.payload;
      break;
    case 'UPDATE_SORT_DATA':
      tempState.sortData = action.payload;
      break;
    case 'UPDATE_MODULES_PAGINATION_DATA':
      tempState.modulesData.pagination = action.payload;
      break;
    case 'UPDATE_FILTER_DATA':
      tempState.filterData = action.payload;
      break;
    case 'UPDATE_MODULES_MODAL_VISIBILITY':
      tempState.showModulesModal = action.payload;
      break;
    case 'UPDATE_MODULES_DATA_AND_MODAL':
      tempState.showModulesModal = action.payload.showModulesModal;
      tempState.editModulesData = action.payload.editModulesData;
      break;
    case 'UPDATE_PAGINATION_DATA':
      
      tempState.modulesData.pagination = action.payload;
      break;
    case 'UPDATE_TOTAL_COUNT':
      tempState.modulesData.pagination.total = action.payload;
      break;
    case 'RESET_PAGINATION':
      tempState.modulesData.pagination.total = 0;
      tempState.modulesData.pagination.pageNumber = 0;
      break;
    default:
      break;
  }
  return tempState;
};

const Modules = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { urlHistory} = useSelector((state) => state.util);
  const {
    modulesData: {status, list,flat, pagination},
    showModulesModal,
    editModulesData, 
  } = state;
  const { pageNumber, count } = pagination;


  useEffect(() => {
    getTotalRecordsCount();
    // getModulesList();
  },[]);

  useEffect(() => {
    getModulesList();
  }, [pageNumber,count]);

  useEffect(()=>{
		const {prev,cur} = urlHistory
		if (prev !== cur) {
			if (pageNumber != 0  ) {
				dispatch({
					type: 'RESET_PAGINATION',
				});
			}
			
		}
	},[urlHistory])

  const modulesLabel = (rowData,key) => {
    return (
    <span
        style={key!=='label'?{}:{
            paddingLeft: `${rowData.level - 1}rem`
        }}
    >{rowData[key].toString()}</span>
  )}

  const buildActionLIcon = (rowData) => {
    return (
	<>
		<Tippy content='Edit'>
			<span>
				<FontAwesomeIcon
					icon={faEdit}
					className='cursor-pointer mr-5'
					data-tip
					data-for='edit-icon'
					onClick={() =>
						handleAction({ ...rowData, new_parent_id: rowData.parent_id })
					}
				/>
			</span>
		</Tippy>
		<Tippy content='Add Child'>
			<span>
				<FontAwesomeIcon
					icon={faPlusSquare}
					className='cursor-pointer mr-5'
					data-tip
					data-for='add-icon'
					onClick={() =>
						handleAction({
							parent_id: rowData._id,
							new_parent_id: rowData._id,
						})
					}
				/>
			</span>
		</Tippy>
	</>

  )}

  const getTableHeader = ()=> {
    return [
      { Header: 'Module Name', id:'expander', accessor: 'label', Cell: rowData => modulesLabel(rowData.row.original,"label") },
      { Header: 'Action', accessor: '', style:{width:'10%',textAlign:"center"}, Cell: rowData => buildActionLIcon(rowData.row.original) }
    ]

  }

  const normalizeData = (child) => {
    let res = []
    if (!child) {
        return res
    }
    res = child.map(x => {
      // console.log("child 0", x.child.length);
        // x.child = x.child.length == 0?null:x.child
        x.subRows = x.child

        if (x.child != null)
            x.subRows = normalizeData(x.child)
        return x
    })
    return res
  }
  
  const maskModules = (modulesx) => {

    let masked = modulesx.map(x => {
        // x.child = x.child.length == 0?null:x.child
        x.subRows = x.child
  
        if (x.child != null)
            x.subRows = normalizeData(x.child)
        return x
    })
  
    return masked
  }

  const serialize = (obj, prefix) => {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

  const getModulesList = () => {
    dispatch({
      type: 'UPDATE_MODULES_DATA_STATUS',
      payload: 'loading',
    });


    axios.get(`/api/v1/modules/getall?page=${pageNumber}&size=${count}`).then(response => {
      let dataflat = []
      if (response.status === 200) {
        if (response.data) {
          const takeOutChild=(child)=>{
            child.forEach(val => {
              dataflat.push(val)
              if (val.child) {
                takeOutChild(val.child)
              }
            });
          }
          response.data.forEach(val => {
            dataflat.push(val)
            if (val.child) {
              takeOutChild(val.child)
            }
          });
        }
        dispatch({
          type: 'UPDATE_MODULES_DATA',
          payload: {
            data:response?.data.length>0?maskModules(response.data) : [],
            dataflat
          },
        });
      } else dispatch({
        type: 'UPDATE_MODULES_DATA',
        payload: {data:[],dataflat},
      });
    }).catch(error => {
      dispatch({
        type: 'UPDATE_MODULES_DATA',
        payload: {data:[],dataflat:[]},
      });
    });
  };

  const getTotalRecordsCount = () => {
    axios
      .get('/api/v1/modules/get/count')
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: 'UPDATE_TOTAL_COUNT',
            payload: response?.data?.data ?? 0,
          });
        } else
          dispatch({
            type: 'UPDATE_TOTAL_COUNT',
            payload: 0,
          });
      })
      .catch((error) => {
        console.log(error.response);
        dispatch({
          type: 'UPDATE_TOTAL_COUNT',
          payload: 0,
        });
      });
  };

  // const handleSortChange = data => {
  //   let tempSortData = {...data};
  //   const currColumnKey = Object.keys(data)[0];

  //   if (tempSortData.hasOwnProperty(currColumnKey)) {
  //     if (data[currColumnKey] === 0) {
  //       delete tempSortData[currColumnKey];
  //     } else {
  //       tempSortData[currColumnKey] = data[currColumnKey];
  //     }
  //   } else tempSortData[currColumnKey] = data[currColumnKey];

  //   dispatch({
  //     type: 'UPDATE_SORT_DATA',
  //     payload: tempSortData,
  //   });
  // };

  const handleAction = (editData) => {
    dispatch({
      type: 'UPDATE_MODULES_DATA_AND_MODAL',
      payload: {
        editModulesData: editData,
        showModulesModal: true,
      },
    });
  };

  const openAddModulesModal = () => {
    dispatch({
      type: 'UPDATE_MODULES_DATA_AND_MODAL',
      payload: {
        editModulesData: {},
        showModulesModal: true,
      },
    });
  };

  const handleModulesSubmit = () => {
    dispatch({
      type: 'UPDATE_MODULES_DATA_AND_MODAL',
      payload: {
        editModulesData: null,
        showModulesModal: false,
      },
    });
    getTotalRecordsCount();
    getModulesList();
  };

  const closeModulesModal = () => {
    console.log("close");
    dispatch({
      type: 'UPDATE_MODULES_DATA_AND_MODAL',
      payload: {
        editModulesData: null,
        showModulesModal: false,
      },
    });
  };

  const handlePaginationChange = (data) => {
		dispatch({
			type: 'UPDATE_PAGINATION_DATA',
			payload: data,
		});
	};

  return (
    <>
      <div className="text-right">
          <button
              className="um-button um-button--enter mb-15"
              onClick={openAddModulesModal}
          >Add Modules
          </button>
      </div>
      <Table className="list-table"
          columns={getTableHeader()}
          data={list}
          pagination={pagination}
          onPaginationChange={handlePaginationChange}
      />
      {
        showModulesModal && (
          <ModulesModal
              title={editModulesData.key?"Edit Modules":"Add Modules"}
              visible={showModulesModal}
              data={editModulesData}
              onSubmit={handleModulesSubmit}
              onCancel={closeModulesModal}
              dropdownData={flat}
          />
        )
      }
    </>
);
};

export default Modules;

