import React, {useState, useEffect, useRef} from 'react';
import { Col, Row } from 'react-grid-system';
import { useForm } from "react-hook-form";
import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { addAlertToDOM } from '../../helpers/util';

const AddEditGroupModal = props => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const modalContentRef = useRef(null);
  const {modalTimeout} = useSelector(state => state.util);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: { 
      name: '',
      // status: false,
    }
  });
  const {visible = false, isAdd, width = 650, data, onSubmit, onCancel} = props;

  useEffect(() => {
    if(!isAdd) {
      const {label = ''} = data ?? {};
      setValue('name', label);
      // setValue('status', status == 1);
    } else reset({ 
        name: '',
        // status: true,
    });
  }, [data]);

  useEffect(() => {
    if (!visible) {
      document.body.style.overflow = 'unset';
      return;
    }

    document.body.style.overflow = 'hidden';

    document.addEventListener('click', hideDropdownMenu);
    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('click', hideDropdownMenu)
    };
  }, [visible]);

  const hideDropdownMenu = event => {
    if (modalContentRef && modalContentRef?.current?.contains(event.target) === false) {
      onCancel && onCancel();
    }
  };

  const closeModal = e => {
    e && e.stopPropagation();
    onCancel && onCancel();
  };

  const handleCancel = e => {
    if(onCancel){
        reset({ 
            name: '',
            // status: false,
        });
        onCancel(e);
    }
  };

  const submitData = ({name}) => {
    setSubmitLoading(true);
    let reqPayload = { 
        "id": name,
        "key": name,
        "label": name,
        // "status": status === true ? 1 : 0
    };

    if(!isAdd){
      const {id = '', key = ''} = data ?? {};
      reqPayload.id = id;
      reqPayload.key = key;
    }

    const axiosMethod = isAdd ? axios.post : axios.put;
    axiosMethod(isAdd ? '/api/v1/groups/add' :  '/api/v1/groups/edit', reqPayload).then(response => {
        console.log(response);
        if(response.status === 200) {
            addAlertToDOM('.add-edit-group-modal .um-modal-body', 'success', `Group ${isAdd ? 'added' : 'updated'} successfully`, 'mb-15');
            onSubmit && setTimeout(() => {
              onSubmit();
              reset({ 
                  name: '',
                  // status: false,
              });
            }, modalTimeout);
        } else addAlertToDOM('.add-edit-modal .um-modal-body', 'warning', 'Something went wrong. Try again.', 'mb-15');
    }).catch(errors => {
        console.log(errors.response);
        addAlertToDOM('.add-edit-modal .um-modal-body', 'warning', 'Something went wrong. Try again.', 'mb-15');
    }).finally(() => setSubmitLoading(false));
  }

  useEffect(()=>{
		if (window?.umApp?.DoOverrideAddEditGroupModalComponent) {
				window?.umApp?.DoOverrideAddEditGroupModalComponent();
			}
	},[])

  const modalContentStyles = {maxWidth: width};

  return (
      <div className={ `add-edit-group-modal um-modal ${visible ? 'show' : 'hide'}`}>
        <div className="um-modal-content" style={modalContentStyles} ref={modalContentRef}>
          <div className="um-modal-header">
            <p className="um-modal-header-text">{isAdd ? 'Add Group' : 'Edit Group'} <span className="close" onClick={closeModal}>&times;</span></p>
          </div>
          <div className="um-modal-body">
            <form  onSubmit={submitLoading?()=>{}:handleSubmit(submitData)} noValidate>
                <Row>
                    <Col xs={12} className="mb-20">
                        <input
                            name="name"
                            type="text"
                            className="um-input full-width"
                            placeholder="Group Name"
                            {...register("name", {
                                required: true,
                            })}
                        />
                        { errors?.name?.type === 'required' && <p className="validation-error">Group name is required</p> }
                    </Col>
                    {/* <Col xs={6} className="mb-20">
                        <div className="checkbox-wrapper">
                            <input
                                id="status"
                                name="status"
                                type="checkbox"
                                {...register("status")}
                            />
                            <label className="cursor-pointer" htmlFor="status">Enable</label>
                        </div>
                    </Col> */}
                    <Col xs={12} className="text-right">
                        <button
                            type='submit'
                            className="um-button um-button--primary mr-10"
                        >
                            {
                                submitLoading ? (
                                    <span>
                                        <FontAwesomeIcon
                                            className="loading-icon mr-5"
                                            icon={faSpinner}
                                            spin
                                        />
                                        Submit
                                    </span>
                                ) : 'Submit'
                            }
                        </button>
                        <button
                            type='reset'
                            className="um-button"
                            onClick={handleCancel}
                        >Cancel
                        </button>
                    </Col>
                </Row>
            </form>
          </div>
        </div>
      </div>
  );
};

export default AddEditGroupModal;
