import React, {useEffect, useReducer, useState} from 'react';
import {useSelector} from 'react-redux';
import {Col, Row} from 'react-grid-system';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {addAlertToDOM} from '../../../../helpers/util';
import { Rights } from '../../../../pages';
import {Table} from '../../../expandable-table/Table'
// import './DefaultRoleLayout.scss';

const buildInitialState = data => {
    const {
        _id = "",
        enable = true,
        role_name = data.name,
        module_list= [],
        updated_by=""
    } = data
    return {
        _id,
        role_name,
        enable,
        module_list,
        updated_by,
    }
  };

const initialState = {
  roleData: {
      _id : "",
      role_name : "",
      enable : true,
      module_list: [],
      updated_by:""
  },
  editData :{},
  submitLoading: false,
};


const reducer = (state = initialState, action) => {
  let tempState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case 'UPDATE_MODULES_DATA':
        tempState.roleData.module_list = action.payload;
      break;
    case 'UPDATE_ROLE_DATA':
      tempState.roleData = action.payload;
      break;
    case 'UPDATE_ROLE_FIELDS_DATA':
      tempState.roleData[action.payload.name] = action.payload.value;
      break;
    // case 'RESET_EDIT_USER_DATA':
    //   tempState
    //   break;
    case 'UPDATE_SUBMIT_LOADING_STATUS':
      tempState.submitLoading = action.payload;
      break;
    case 'UPDATE_EDIT_DATA':{
        tempState.editData = action.payload
    }
    default:
      break;
  }
  return tempState;
};

const DefaultRoleLayout = ({data, onSubmit, onCancel}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {modules, rights, modalTimeout} = useSelector(state => state.util);
  const [higestLevel,setHigetsLevel] = useState(1)
  useEffect(() => {
      dispatch({
        type: 'UPDATE_ROLE_DATA',
        payload: buildInitialState(data??{_id : "",
        name : "",
        enable : true,
        module_list: [],
        updated_by:""}),
      })
    if (data) {
        buildEditData(modules,data.modules)
    }else{
        buildAdddata(modules)
    }
  },[data]);

const buildDefaultRights = (rights)=>{
    let right = {}
    rights.forEach(val => {
        if (val.status===1) {
            let keystr = val.key.toLocaleLowerCase()
            right[keystr] = false
        }
    });
    return right
}

const buildEditData = (modulesx,data)=>{
    let editDataObject = {}
    const pullOutEditObject = (child)=>{
        for (const modulekey in child) {
            if (Object.hasOwnProperty.call(child, modulekey)) {
                const module = child[modulekey];
                editDataObject[modulekey] =module
                if (module.child != null) {
                    pullOutEditObject(module.child)
                }
            }
        }
    }
    for (const modulekey in data) {
        if (Object.hasOwnProperty.call(data, modulekey)) {
            const module = data[modulekey];
            editDataObject[modulekey] =module
            if (module.child != null) {
                pullOutEditObject(module.child)
            }
        }
    }

    const normalizeDataEdit = child =>{
        let res = []
        if (!child) {
            return res
        }
        res = child.map(x => {
            x.subRows = x.child
            x.rights = editDataObject[x.key] == undefined ? buildDefaultRights(rights) : editDataObject[x.key].rights;
            // if (x.level > higestLevel) {
            //     setHigetsLevel(x.level)
            // }

            if (x.child != null)
                x.subRows = normalizeDataEdit(x.child)
            return x
        })
        return res
    }

    const maskedEditModul = modulesx.map(x => {
        x.subRows = x.child
        x.rights = editDataObject[x.key] == undefined ? buildDefaultRights(rights) : editDataObject[x.key].rights;

        if (x.child != null)
            x.subRows = normalizeDataEdit(x.child)
        return x
    });
    dispatch({
        type : "UPDATE_MODULES_DATA",
        payload : maskedEditModul
    })
}

const buildAdddata = modulesx=>{
    const normalizeDataAdd = (child) => {
        let res = []
        if (!child) {
            return res
        }
        res = child.map(x => {
            x.subRows = x.child
            x.rights = buildDefaultRights(rights);
            if (x.child != null)
                x.subRows = normalizeDataAdd(x.child)
            return x
        })
        return res
    }
    let maskedAddModul = modulesx.map(x => {
        x.subRows = x.child
        x.rights = buildDefaultRights(rights)
    
        if (x.child != null)
            x.subRows = normalizeDataAdd(x.child)
        return x
    })

    dispatch({
        type : "UPDATE_MODULES_DATA",
        payload : maskedAddModul
    })
}

const normalizeData = (child) => {
    let res = []
    if (!child) {
        return res
    }
    res = child.map(x => {
        // x.child = x.child.length == 0?null:x.child
        x.subRows = x.child
        x.rights = x.rights == undefined ? buildDefaultRights(rights) : x.rights;

        if (x.child != null)
            x.subRows = normalizeData(x.child)
        return x
    })
    return res
  }
  
  const maskModules = (modulesx) => {

    let masked = modulesx.map(x => {
        // x.child = x.child.length == 0?null:x.child
        x.subRows = x.child
        x.rights = x.rights == undefined ? buildDefaultRights(rights) : x.rights;
  
        if (x.child != null)
            x.subRows = normalizeData(x.child)
        return x
    })
  
    return masked
  }

const prepareSaveModulesObject = (modulesx) => {
    let res = {}
    const workInsideChild = (child)=>{
        let resC = {}
        child.forEach(val => {
            resC[val.key] = {...val,
                child : val.child == null || val.child.lenght ==0 ? null : workInsideChild(val.child)
            }
        });
        return resC
    }

    modulesx.forEach(val => {
        res[val.key] = {...val,
            child : val.child == null || val.child.lenght ==0 ? null : workInsideChild(val.child)
        }
    });
    return res
}

const handleRoleFieldsChange = e => {
    const {name, type, value, checked} = e.target;
    dispatch({
        type: 'UPDATE_ROLE_FIELDS_DATA',
        payload: {
        name: name,
        value: type === 'checkbox' ? checked == true : value,
        },
    });
};

const submitData = () => {
    // validation
    if (role_name.trim() == "") {
        addAlertToDOM('.deul-section', 'warning', 'Role Name should be fill', 'mb-15');
       return 
    }
    dispatch({
        type: 'UPDATE_SUBMIT_LOADING_STATUS',
        payload: true,
    });


    if (_id=="") {
        const requestPayload = {
            'name':role_name.trim(),
            enable,
            'modules': prepareSaveModulesObject(module_list),
        }
        axios.post('/api/v1/roles/add', requestPayload).then(response => {
            if (response.status === 200) {
            addAlertToDOM('.deul-section', 'success', 'Role data added successfully', 'mb-15');
            onSubmit && setTimeout(() => onSubmit(), modalTimeout);
            } else addAlertToDOM('.deul-section', 'warning', 'Something went wrong. Try again.', 'mb-15');
        }).catch(error => {
            addAlertToDOM('.deul-section', 'warning', 'Something went wrong. Try again.', 'mb-15');
        }).finally(() => dispatch({
            type: 'UPDATE_SUBMIT_LOADING_STATUS',
            payload: false,
        }));
    } else{
        const requestPayload = {
            _id,
            'name':role_name.trim(),
            enable,
            'modules': prepareSaveModulesObject(module_list),
        }
        axios.put('/api/v1/roles/edit', requestPayload).then(response => {
            if (response.status === 200) {
            addAlertToDOM('.deul-section', 'success', 'Role data updated successfully', 'mb-15');
            onSubmit && setTimeout(() => onSubmit(), modalTimeout);
            } else addAlertToDOM('.deul-section', 'warning', 'Something went wrong. Try again.', 'mb-15');
        }).catch(error => {
            addAlertToDOM('.deul-section', 'warning', 'Something went wrong. Try again.', 'mb-15');
        }).finally(() => dispatch({
            type: 'UPDATE_SUBMIT_LOADING_STATUS',
            payload: false,
        }));
    }


};

const handleCancel = e => {
    onCancel && onCancel(e);
    dispatch({
        type: 'RESET_EDIT_USER_DATA',
    });
};

  const checkbox = (rowData, fieldname) => {
      return (
        <input
            _id={rowData.key}
            name={rowData.key}
            checked={rowData.rights[fieldname]}
            type="checkbox"
            onChange={e => {
                e.stopPropagation();
                handleChangeCheckBox(rowData, fieldname);
            }}
        />
    )}

const roleLabel = (rowData) => (
    <span
        style={{
            paddingLeft: `${rowData.level - 1}rem`
        }}
    >{rowData.label}</span>
)

const setChildTrue = (allData, fieldname, state) => {
    allData.map(x => {
        x.rights[fieldname] = x.rights[fieldname] == undefined ? false : x.rights[fieldname];
        x.rights[fieldname] = state
        if (x.child != null) {
            x.child = setChildTrue(x.child, fieldname, state)
        }
    })
    return allData
}

const handleCheckBoxOnClick = (allData, rowData, fieldname) => {
    allData.map(x => {
        if (x._id == rowData._id) {
            x.rights[fieldname] = x.rights[fieldname] == undefined ? false : x.rights[fieldname];
            x.rights[fieldname] = !x.rights[fieldname]
            if (x.child != null) {
                x.child = setChildTrue(x.child, fieldname, x.rights[fieldname])
            }
        } else {
            if (x.child != null) {
                x.child = handleCheckBoxOnClick(x.child, rowData, fieldname)
            }
        }
        return x
    })
    return allData
}

const handleChangeCheckBox = (rowData, fieldname) => {
    let allData = module_list;
    allData = handleCheckBoxOnClick(allData, rowData, fieldname);
    dispatch({
        type : "UPDATE_MODULES_DATA",
        payload : maskModules(allData)
    })
}

const getTableHeader = ()=> {
    const generateRightHeader=dt=>{
        let res = []
        dt.forEach(val => {
            if (val.status === 1) {
                res.push({Header : val.label,style:{width:"10%"}, accessor:`rights.${val.key.toLocaleLowerCase()}`, Cell: rowData => { return <div className='text-center'>{checkbox(rowData.row.original, val.key.toLocaleLowerCase())}</div>}})
            }
        });
        return res
    }
    const generateModuleLabel = ()=>{
        let res = [{ Header: 'Module Name',id:"expander", accessor: 'label'}]
        // for (let i = 2; i <= higestLevel; i++) {
        //     res.push({ Header: <span>{`Sub Module ${higestLevel!==2?i-1:''}`}</span>, accessor: 'label-'+i})
        // }
        return res
    }
    return [...generateModuleLabel(),
    ...generateRightHeader(rights)
    ]
}

const {
    roleData: {
        _id,
        role_name,
        enable,
        module_list,
    },
    editData,
    submitLoading
} = state;

  return (
      <div className="deul-section">
        <Row>
            <Col xs={6}>
                <input
                    name="role_name"
                    type="text"
                    className="um-input full-width mb-15"
                    placeholder="Role Name"
                    value={role_name}
                    onChange={handleRoleFieldsChange}
                />
            </Col>
            <Col xs={6} className="mb-20 item-center">
                <div className="checkbox-wrapper">
                    <input
                        id="enable"
                        name="enable"
                        type="checkbox"
                        checked={enable}
                        onChange={handleRoleFieldsChange}
                    />
                    <label className="cursor-pointer" htmlFor="enable">Enable</label>
                </div>
            </Col>
            <Col xs={12}>
                <Table 
                    className="list-table"
                    columns={getTableHeader()}
                    data={module_list}
                    disablePagination={true}
                />
            </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-right">
            <button
                type='submit'
                className="um-button um-button--primary mr-10"
                onClick={submitLoading?()=>{}:submitData}
            >
              {
                submitLoading ? (
                  <span>
                    <FontAwesomeIcon
                        className="loading-icon mr-5"
                        icon={faSpinner}
                        spin
                    />
                    Submit
                  </span>
                ) : 'Submit'
              }
            </button>
            <button
                className="um-button"
                onClick={handleCancel}
            >Cancel
            </button>
          </Col>
        </Row>
      </div>
  );
};

export default DefaultRoleLayout;
