import React, { useEffect, useRef } from 'react';
import addUserLayout from './inner_components/add_user_layout/index';

const AddUserModal = (props) => {
	const modalContentRef = useRef(null);
	const {
		title = '',
		visible = false,
		width = 650,
		onSubmit,
		onCancel,
	} = props;

	useEffect(() => {
		if (!visible) {
			document.body.style.overflow = 'unset';
			return;
		}

		document.body.style.overflow = 'hidden';

		document.addEventListener('click', hideDropdownMenu);
		return () => {
			document.body.style.overflow = 'unset';
			document.removeEventListener('click', hideDropdownMenu);
		};
	}, [visible]);

	const hideDropdownMenu = (event) => {
		event && event.stopPropagation();
		if ( modalContentRef && modalContentRef.current.contains(event.target) === false) {
			// onCancel && onCancel();
		}
	};

	const closeModal = (e) => {
		e && e.stopPropagation();
		onCancel && onCancel();
	};

	const getAddUserLayout = () => {
		return addUserLayout({
			key: `add-user`,
			component: `add-user`,
			onSubmit,
			onCancel: closeModal,
		});
	};

	const modalContentStyles = { maxWidth: width };

	useEffect(()=>{
		if (window?.umApp?.DoOverrideAddUserModalComponent) {
				window?.umApp?.DoOverrideAddUserModalComponent();
			}
	},[])

	return (
		<div className={visible ? 'um-modal show' : 'um-modal hide'}>
			<div
				className='um-modal-content'
				style={modalContentStyles}
				ref={modalContentRef}
			>
				<div className='um-modal-header'>
					<p className='um-modal-header-text'>
						{title}{' '}
						<span className='close' onClick={closeModal}>
							&times;
						</span>
					</p>
				</div>
				<div className='um-modal-body'>{getAddUserLayout()}</div>
			</div>
		</div>
	);
};

export default AddUserModal;
