import React from "react";
import { useTable, useExpanded } from "react-table";
import ReactPaginate from 'react-paginate';
// import Icon from '@material-ui/core/Icon';
// import IconButton from "@material-ui/core/IconButton";
import "./Table.scss"
import {faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function Table({ 
  columns: userColumns, 
  data, 
  className, 
  autoResetExpanded=false,
  disablePagination,
  pagination= {
    pageNumber: 0,
    count: 10,
    total: 0,
  },
  onPaginationChange,
}) {
  if (userColumns[0].accessor.includes("label") && userColumns[0].id == "expander" && typeof(userColumns[0].Header)=="string") {
    let dt = {...userColumns[0]}
    let tmpColumn = {...userColumns[0],
      id : "expander",
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded, }) => (
        <span {...getToggleAllRowsExpandedProps()}>
          <span className="unselectable">
            {isAllRowsExpanded ?
              <div className="text-left"><FontAwesomeIcon icon={faChevronDown} className="cursor-pointer"/> {dt.Header}</div>
              :
              <div className="text-left"><FontAwesomeIcon icon={faChevronRight} className="cursor-pointer"/> {dt.Header}</div>
            }
          </span>
        </span>
      ),
      Cell: ({ row, cell:{value} }) =>
      
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth*1.5}rem`
                }
              })}
            >
              <span className="unselectable">
                {row.isExpanded ?
                  <FontAwesomeIcon icon={faChevronDown} className="cursor-pointer"/>
                  :
                  <FontAwesomeIcon icon={faChevronRight} className="cursor-pointer"/>}
              </span>
              {` ${value}`}
            </span>
          ) : (
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    // We can even use the row.depth property
                    // and paddingLeft to indicate the depth
                    // of the row
                    paddingLeft: `${row.depth*1.5}rem`
                  }
                })}
              >
              {` ${value}`}
              </span>
            ),
      }
    userColumns[0] = tmpColumn
  }
  else if (!userColumns[0].id?.includes("expander")) {
    userColumns.unshift(
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            <span className="unselectable">
              {isAllRowsExpanded ?
                <span><FontAwesomeIcon icon={faChevronDown} className="cursor-pointer"/></span>
                :
                <span><FontAwesomeIcon icon={faChevronRight} className="cursor-pointer"/></span>
              }
            </span>
          </span>
        ),
        accessor: "",
        Cell: ({ row, cell: { value } }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth*1.5}rem`
                }
              })}
            >
              <span className="unselectable">
                {row.isExpanded ?
                  <FontAwesomeIcon icon={faChevronDown} className="cursor-pointer"/>
                  :
                  <FontAwesomeIcon icon={faChevronRight} className="cursor-pointer"/>}
              </span>{" "}
              {value}
            </span>
          ) : (
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    // We can even use the row.depth property
                    // and paddingLeft to indicate the depth
                    // of the row
                    paddingLeft: `${row.depth*1.5}rem`
                  }
                })}
              >
                {value}
              </span>
            ),
        style:{width:'10%'}
      }
    )
  }

  const {pageNumber, count, total} = pagination;
  const currPageStartValue = pageNumber * count;

  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded }
  } = useTable(
    {
      columns: userColumns,
      data,
      autoResetExpanded
    },
    useExpanded, // Use the useExpanded plugin hook
  );

  const handlePaginationChange = ({selected}) => {
    onPaginationChange({...pagination, pageNumber: selected});
  };


  return (
    <>
    <div className={className}>
      <table {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => { 
                // console.log(column.width); 
              return (
                <th {...column.getHeaderProps()} style={column.style}>{column.render("Header")}</th>
              )})}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps({ className : ( row.canExpand ? "hasChild" : "")  })}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
    {
      !disablePagination && (
          <div className="dt-pagination-container">
      <div className="dt-pagination-container-left">
        <ReactPaginate
            forcePage={pageNumber}
            disableInitialCallback={true}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={total / count}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePaginationChange}
            containerClassName={'dt-pagination'}
            pageLinkClassName={'dt-page-link'}
            activeClassName={'active'}
            previousClassName={'dt-prev-page'}
            previousLinkClassName={'dt-prev-page-link'}
            nextClassName={'dt-next-page'}
            nextLinkClassName={'dt-next-page-link'}
            disabledClassName={'disabled'}
        />
      </div>
      <div className="dt-pagination-container-right">
        <p>{currPageStartValue + 1} - {currPageStartValue + count > total ?
            total :
            currPageStartValue + count} of {total} items</p>
      </div>
    </div>
      )
    }
    </>
  );
}
export { Table }
