import React from 'react';
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import DataTableHeaderFilter from '../data_table_header_filter/DataTableHeaderFilter';
import DataTableHeaderAggregation from '../data_table_header_aggregation/DataTableHeaderAggregation';
import { calculateFixedWidthForColumn } from '../../../../helpers/util';

const SortableDataTableHeader = (props) => {
	const {
		columnHeaders,
		enableColumnFilters,
		lastFixedColNamespace,
		data,
		index,
		sortData,
		onSortChange,
		filterData,
		onFilterChange,
	} = props;

	const { label, key, namespace, filter, aggregation = false, fixed } = data;

	const sortStatus =
		sortData && sortData.hasOwnProperty(namespace) ? sortData[namespace] : 0;

	const handleSort = () => {
		let tempOrder = sortStatus;
		if (tempOrder === 0) {
			tempOrder = 1;
		} else if (tempOrder === 1) {
			tempOrder = -1;
		} else if (tempOrder === -1) {
			tempOrder = 0;
		}
		onSortChange({ [namespace]: tempOrder });
	};

	const buildSHClassNames = `dt-th${fixed === true ? ' fixed' : ''}`;
	const buildSHStyles =
		fixed === true
			? {
					left:
						index === 0
							? 0
							: calculateFixedWidthForColumn(index, columnHeaders),
			  }
			: {};
	const buildSortClassNames =
		sortStatus === 1
			? 'dt-sorter ascending'
			: sortStatus === -1
			? 'dt-sorter descending'
			: 'dt-sorter';
	const buildSortUpClassNames =
		sortStatus === 1 ? 'dt-sort-up active' : 'dt-sort-up';
	const buildSortDownClassNames =
		sortStatus === -1 ? 'dt-icon dt-sort-down active' : 'dt-sort-down';

	return (
		<div
			key={namespace}
			data-field-name={key}
			data-field-namespace={namespace}
			data-class={key}
			className={buildSHClassNames}
			style={buildSHStyles}
		>
			<Tippy
				content={
					<div className='text-left'>
						<p className='m-0 p-0'>Name: {key}</p>
						<p className='m-0 p-0'>Alias: {label}</p>
					</div>
				}
			>
				<div className='dt-header-cell'>
					<div
						className='dt-header-cell-inner sortable'
						data-tip
						data-for={'tooltip' + index}
						onClick={handleSort}
					>
						<span>{label}</span>
						<span className={buildSortClassNames}>
							<FontAwesomeIcon
								icon={faSortUp}
								className={buildSortUpClassNames}
							/>
							<FontAwesomeIcon
								icon={faSortDown}
								className={buildSortDownClassNames}
							/>
						</span>
					</div>
					{filter?.trueBackendType === 'number' &&
						filter?.type === 'number' &&
						aggregation && <DataTableHeaderAggregation data={data} />}
					{enableColumnFilters &&
						filter &&
						filter.type !== 'none' &&
						namespace !== '' && (
							<DataTableHeaderFilter
								data={data}
								lastFixedColNamespace={lastFixedColNamespace}
								filterData={filterData}
								onFilterChange={onFilterChange}
							/>
						)}
				</div>
			</Tippy>
		</div>
	);
};

function areEqual(prevProps, nextProps) {
	return prevProps.data.namespace === nextProps.data.namespace;

	/*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}

export default SortableDataTableHeader;
