import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import DataTable from '../../components/data_table/DataTable';
import AddEditGroupModal from '../../components/add_edit_group_modal/AddEditGroupModal';

const tableHeaders = [
  {
    key: 'sno',
    label: 'S.NO',
    namespace: '',
    width: 70,
    filter: {
      'type': 'none',
    },
  },
  {
    key: 'label',
    label: 'Name',
    namespace: 'label',
    width: 400,
    filter: {
      'type': 'none',
      'subType': 'search',
      'trueBackendType': 'string',
    },
  },
  // {
  //   key: 'enable',
  //   label: 'Enable',
  //   namespace: 'status',
  //   width: 65,
  //   filter: {
  //     'type': 'none',
  //   },
  // },
  {
    key: 'action',
    label: 'Action',
    namespace: 'action',
    width: 40,
    type: 'all',
    filter: {
      'type': 'none',
    },
  },
];

const initialState = {
  groupsData: {
    status: 'loading',
    list: [],
    pagination: {
      pageNumber: 0,
      count: 10,
      total: 0,
    },
  },
  showAddEditGroupModal: false,
  isAddModal: true,
  editGroupData: null,
}

const reducer = (state = initialState, action) => {
  let tempState = {...state};

  switch(action.type){
    case 'update_loading_status':
      tempState.groupsData.status = action.payload;
      break;
    case 'update_groups_data':
      tempState.groupsData.status = 'success';
      tempState.groupsData.list = action.payload;
      break;
    case 'update_add_edit_group_modal_visibility':
      tempState.showAddEditGroupModal = action.payload.showAddEditGroupModal;
      tempState.isAddModal = action.payload.isAddModal;
      tempState.editGroupData = action.payload.editGroupData;
      break;
    case 'UPDATE_PAGINATION_DATA':
      tempState.groupsData.pagination = action.payload;
      break;
    case 'UPDATE_TOTAL_COUNT':
      tempState.groupsData.pagination.total = action.payload;
      break;
    case 'RESET_PAGINATION':
      tempState.groupsData.pagination.total = 0;
      tempState.groupsData.pagination.pageNumber = 0;
      break;
    default:
      break;
  }
  return tempState;
}

const Groups = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { urlHistory} = useSelector((state) => state.util);
  const reduxDispatch = useDispatch();
  const { groups } = window['runConfig'];
  const {groupsData: { status, list, pagination }, showAddEditGroupModal, isAddModal, editGroupData} = state;
  const { pageNumber, count } = pagination;

  useEffect(() => {
    getTotalRecordsCount();
  }, []);

  useEffect(() => {
    getGroups();
  }, [pageNumber,count]);

  useEffect(()=>{
		const {prev,cur} = urlHistory
		if (prev !== cur) {
			if (pageNumber != 0  ) {
				dispatch({
					type: 'RESET_PAGINATION',
				});
			}
			
		}
	},[urlHistory])

  const getGroups = () => {
    dispatch({
      type: 'update_loading_status',
      payload: 'loading'
    })
    axios.get(`/api/v1/groups/getall?page=${pageNumber}&size=${count}`).then(response => {
      if (response.status === 200) {
        const data = response?.data ?? [];
        dispatch({
          type: 'update_groups_data',
          payload: data
        })
      } else dispatch({
          type: 'update_groups_data',
          payload: []
        })
    }).catch(error => {
      console.log(error.response);
      dispatch({
          type: 'update_groups_data',
          payload: []
        })
    });
  };

  const updateGlobalData = () => {
    axios.get('/api/v1/groups/getall').then(response => {
      if (response.status === 200) {
        const data = response?.data ?? [];
        reduxDispatch({
          type: 'UPDATE_GROUPS_DATA',
          payload: data,
        });
      }
    }).catch(error => {
      console.log(error.response);
    });
  };

  const getTotalRecordsCount = (requestPayload) => {
    axios
      .get('/api/v1/groups/get/count')
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: 'UPDATE_TOTAL_COUNT',
            payload: response?.data?.data ?? 0,
          });
          updateGlobalData()
        } else
          dispatch({
            type: 'UPDATE_TOTAL_COUNT',
            payload: 0,
          });
      })
      .catch((error) => {
        console.log(error.response);
        dispatch({
          type: 'UPDATE_TOTAL_COUNT',
          payload: 0,
        });
      });
  };


  const handleAddGroup = () => dispatch({
    type: 'update_add_edit_group_modal_visibility',
    payload: {
        showAddEditGroupModal: true,
        isAddModal: true,
        editGroupData: null
    }
  });

  const handleAction = (actionType, index) => {
    dispatch({
        type: 'update_add_edit_group_modal_visibility',
        payload: {
            showAddEditGroupModal: true,
            isAddModal: false,
            editGroupData: list?.[index] ?? null
        }
    });
  }

  const closeAddEditGroupModal = () => dispatch({
    type: 'update_add_edit_group_modal_visibility',
    payload: {
        showAddEditGroupModal: false,
        isAddModal: true,
        editGroupData: null
    }
  });

  const handleAddEditGroupDataSubmit = () => {
    closeAddEditGroupModal();
    getTotalRecordsCount();
    getGroups();
  }

  const handlePaginationChange = (data) => {
		dispatch({
			type: 'UPDATE_PAGINATION_DATA',
			payload: data,
		});
	};

  useEffect(()=>{
		if (window?.umApp?.DoOverrideGroupsPage) {
			window?.umApp?.DoOverrideGroupsPage();
		}
	},[])

  

  return (
    <>
      {groups?.add?<div  className="text-right mb-15">
        <button className="um-button um-button--enter" onClick={handleAddGroup}>Add</button>
      </div>:""}
      <DataTable
        loading={status === 'loading'}
        columnHeaders={groups?.tableHeader??tableHeaders}
        data={list}
        sortData={{}}
        // onSortChange={data => console.log(data)}
        filterData={{}}
        // onFilterChange={data => console.log(data)}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        actionType="edit"
        onAction={handleAction}
      />
      {
        showAddEditGroupModal && (
          <AddEditGroupModal
            isAdd={isAddModal}
            visible={showAddEditGroupModal}
            data={editGroupData}
            onSubmit={handleAddEditGroupDataSubmit}
            onCancel={closeAddEditGroupModal}
          />
        )
      }
    </>
  );
};

export default Groups;
